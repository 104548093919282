$variable-prefix: psp-;

$light-gray: $gray-200;
$lighter-gray : #C1C1C6;
$lightest-gray: #f5f5f5;
$dark-gray: $gray-800;

$primary-color : #6bbbae;
$primary-color-light: lighten($primary-color, 5%);
$primary-color-lighter: lighten($primary-color, 15%);
$primary-color-dark: darken($primary-color, 20%);
$header-background: $primary-color;

$secondary-color : #D6393B;
$secondary-color-lighter: lighten($secondary-color, 40%);
$secondary-color-dark: darken($secondary-color, 5%);
$tertiary-color : #EE4028;
$tertiary-color-light: lighten($tertiary-color, 15%);
$tertiary-color-lighter: lighten($tertiary-color, 40%);

$icon-color : $tertiary-color;
$box-shadow-color : #e5e5e5;
$link-color : $primary-color-dark;

$border-color : $gray-500;
$border-radius : 5px;
$transition-count : 0.5s;

$body-bg: #f0f0f0;
$primary-font: $font-family-base;

$default-gutter: $spacer;
$sidebar-border : 1px solid lighten($secondary-color, 10%);

// branding setting
$logo-size : 247px;

// schedule style setting
$schedule-circle : 15px;
$schedule-border : 5px;
$schedule-time-offset : 120px;

// menu setting
$menu-icon-color : $primary-color;
$menu-icon-hover-color : #ff8800;
$menu-footer-icon-color : $tertiary-color;
$menu-footer-icon-hover-color : darken($tertiary-color, 10%);
$menu-active-border-color : $tertiary-color;
$menu-active-bg-color : darken($secondary-color, 10%);
$menu-icon-position: ($default-gutter + 10) center;

$icon-only-left-menu-width : 60px;
$expand-menu-width-mobile : 200px;
$mobile-expand-trigger-height : 40px;
$expand-menu-branding-height : 80px;

// Fonts
$font-family-base:  $font-open-sans;
$icon-path: '../images/icons' !default;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,700;1,800&display=swap');
$font-family-poppins:         'Poppins', sans-serif;

// Site Container
$site-max-width : 1200px;

// Ratio
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);
