@mixin svg-icon-background($name, $color, $hover-color:null, $position:null, $size:null) {
  background-image: svg-load('#{$icon-path}/#{$name}.svg', fill='#{$color}');
  background-position: $position;
  background-size: $size;
  background-repeat: no-repeat;

  &:hover {
    background-image: svg-load('#{$icon-path}/#{$name}.svg', fill='#{$hover-color}');
  }
}

@mixin svg-inline($name, $position, $color, $size, $margin) {
  &::#{$position} {
    content: '';
    width: $size;
    height: $size;
    display: inline-block;
    background-image: svg-load('#{$icon-path}/#{$name}.svg', fill='#{$color}');
    background-position: 0 center;
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;

    @if $position == after {
      margin-left: $margin;
    } @else {
      margin-right: $margin;
    }
  }
}

@mixin svg-icon-replace($name, $color, $hover-color, $size:null) {
	background-image: svg-load('#{$icon-path}/#{$name}.svg', fill='#{$color}');
	background-repeat: no-repeat;
	overflow: hidden;
	text-indent: -9999px;
	display: block;
	width: $size;
	height: $size;
	&:hover {
		background-image: svg-load('#{$icon-path}/#{$name}.svg', fill='#{$hover-color}');
	}
}

// Position
@mixin position($position, $top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Circle
@mixin circle($dimention, $color) {
  width: $dimention;
  height: $dimention;
  border-radius: 50%;
  background: $color;
}

// background option
@mixin background-color-option {
  @at-root {
    .paragraph--bg-white & {
      background-color: $white;
    }

    .paragraph--bg-light-gray & {
      background-color: $light-gray;
    }

    .paragraph--bg-primary & {
      background-color: $primary-color;
    }

    .paragraph--bg-secondary & {
      background-color: $secondary-color;
    }

    .paragraph--bg-dark-gray &,
    .paragraph--bg-primary &,
    .paragraph--bg-secondary & {
      color: $white;

      path {
        fill: $white;
      }

      a {
        color: $white;
      }

      .icon path {
        fill: $white;
      }

      .cta-link a {
        @include svg-inline(right-arrow, after, $white, 20px, 5px);
      }
    }
  }
}

// background option
@mixin background-self-color-option {
  &.paragraph--bg-white {
    background-color: $white;
  }

  &.paragraph--bg-light-gray {
    background-color: $light-gray;
  }

  &.paragraph--bg-primary {
    background-color: $primary-color;
  }

  &.paragraph--bg-secondary {
    background-color: $secondary-color;
  }

  &.paragraph--bg-dark-gray ,
  &.paragraph--bg-primary ,
  &.paragraph--bg-secondary {
    a {
      color: $white;
    }

    .icon path {
      fill: $white;
    }

    .cta-link a {
      @include svg-inline(right-arrow, after, $white, 20px, 5px);
    }
  }
}

// columns option
@mixin column-option {
  @at-root {
    .paragraph--col-1 & {
      width: span(12 of 12);
    }

    .paragraph--col-2 & {
      width: span(6 of 12);
    }

    .paragraph--col-3 & {
      width: span(4 of 12);
    }

    .paragraph--col-4 & {
      width: span(3 of 12);
    }
  }
}

// breakpoint max width
@mixin breakpoint-max($bp) {
  @media screen and (max-width: #{$bp}) {
    @content;
  }
}

// Boxed group
@mixin boxed {
  padding: $spacer $spacer*2;
  background: $light-gray;
  border: solid 1px $border-color;
  border-radius: $border-radius;
  margin: $spacer 0;
}

// Box shadow
@mixin box-shadow( $h: 10px , $v: 10px , $b: 10px , $s: 0px , $c: rgba(0,0,0,0.75) ) {
  -webkit-box-shadow: $h $v $b $s $c;
  -moz-box-shadow: $h $v $b $s $c;
  box-shadow: $h $v $b $s $c;
}

@mixin default-box-shadow() {
  @include box-shadow(0px, 3px, 6px, 0, rgba(0,0,0,0.16));
}

// Gradient
@mixin gradient($start-color, $end-color, $orientation) {
	background: $start-color;
	@if $orientation == vertical {
		// vertical
		background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
		background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
		background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
		background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
		background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
	}
	@else if $orientation == horizontal	{
		// horizontal
		background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
		background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
		background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
		background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
		background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
	}
	@else	{
		// radial
		background: -moz-radial-gradient(center, ellipse farthest-corner,  $start-color 0%, $end-color 100%);
		background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
		background: -webkit-radial-gradient(center, ellipse farthest-corner,  $start-color 0%,$end-color 100%);
		background: -o-radial-gradient(center, ellipse farthest-corner,  $start-color 0%,$end-color 100%);
		background: -ms-radial-gradient(center, ellipse farthest-corner,  $start-color 0%,$end-color 100%);
		background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
	}
}

// Fade
@mixin fade($type) {
  @if $type== "hide" {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s;
  } @else if $type== "show" {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
  }
}

// Button
@mixin btn {
  border-radius: 30px;
  font-weight: $font-weight-medium;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  padding: $spacer $spacer*2;
  margin: $spacer 0;
  border: none;
  cursor: pointer;
  font-size: $font-size-base;
}

@mixin btn-color($bg, $bg-hover, $color: $white, $hover: $white) {
  background-color: $bg;
  color: $color;
  &:hover {
    background-color:$bg-hover;
    color:$hover;
  }
}
