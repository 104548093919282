body {
  background: #333;
  color: #333;
  line-height: 26px;
  font-family: 'avenir_next_lt_promedium';
  font-weight: normal;
}

h1, h2, h3 {
  font-family: 'avenir_next_lt_probold';
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p {
  margin: 0 0 1em 0;
  font-size: 0.969em;
  color: #000;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.container {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}

.page-map #main-wrapper .container {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

/*form*/
form input[type="text"],
form input[type="password"],
form input[type="email"],
form textarea,
form .container-inline-date input[type="text"],
.form-type-select select {
  width: 100%;
  padding: 0.9375rem 1.25rem;
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #f0f4f7;
  line-height: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

form .views-exposed-form input[type="text"],
form .views-exposed-form select,
form .views-sort-options select {
  padding: 10px 15px;
}

form .views-exposed-form select,
form .views-sort-options select {
  background-position: top -1460px right -5px;
  font-size: 15px;
}

form .views-exposed-form label,
form .views-sort-options select {
  font-size: 15px;
  line-height: 22px;
}

form .views-exposed-form label {
  margin-bottom: 5px;
}

.form-type-select select {
  background-image: url('../images/icon-sprites.png');
  background-repeat: no-repeat;
  background-position: top -1453px right -5px;
  padding-right: 40px;
}

form label {
  font-family: 'avenir_next_lt_probold', sans-serif;
  margin-bottom: 10px;
}

.not-logged-in form label {
  font-family: 'avenir_next_lt_promedium';
}

form .form-item {
  margin-bottom: 20px;
}

table {
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

table thead tr {
  background: #f1efef;
  border-bottom: 1px solid #cdcdcd;
}

table thead th {
  padding: 15px 20px;
}

table tbody td {
  padding: 15px 20px;
}

table tbody tr.odd {
  background-color: #ffffff;
}

table tbody tr.even {
  background-color: #f9f9f9;
}

table tbody tr.odd,
table tbody tr.even {
  border-bottom: 1px solid #cdcdcd;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1em 0;
  color: #333;
}

h3 {
  font-family: 'avenir_next_lt_probold';
  font-weight: normal;
}

strong {
  font-family: 'avenir_next_lt_probold';
  font-weight: normal;
}

.nomargin {
  margin: 0;
}

tbody {
  border: none;
}

th {
  border: none;
}

ul, .item-list ul {
  list-style: none;
  margin: 0 0 1em 20px;
  padding: 0 ;
}

ul li, .item-list ul li {
  list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFElEQVQYlWO4Wuz4nxjMMKqQvgoBsTj9IQQCqXsAAAAASUVORK5CYII=');
  margin: 0;
  padding: 0;
}

ul.content-bullet li {
  list-style-image: url('../images/bullet-img.jpg');
  font-size: .969em;
  color: #555;
}

label {
	font-weight: normal;
}

img {
  max-width: 100%;
}

.logged-in #main-wrapper,
.not-logged-in.not-front #main-wrapper {
  margin-top: 40px;
}

.page-map .logged-in #main-wrapper {
  margin-top: 0;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right;
}

.grid-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
}

.grid-layout--space-between {
  -webkit-box-pack: justify;
    -ms-flex-pack: justify;
        justify-content: space-between;
}

.grid-layout--valign-center {
  -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
}

.grid-col-1 {width: 6.77966%;}
.grid-col-2 {width: 15.25424%;}
.grid-col-3 {width: 23.72881%;}
.grid-col-4 {width: 32.20339%;}
.grid-col-5 {width: 40.67797%;}
.grid-col-6 {width: 49.15254%;}
.grid-col-7 {width: 57.62712%;}
.grid-col-8 {width: 66.10169%;}
.grid-col-9 {width: 74.57627%;}
.grid-col-10 {width: 83.05085%;}
.grid-col-11 {width: 91.52542%;}
.grid-col-12 {width: 100%;}

.grid-col-f-1 {width: 8.33333%;}
.grid-col-f-2 {width: 16.66667%;}
.grid-col-f-3 {width: 25%;}
.grid-col-f-4 {width: 33.33333%;}
.grid-col-f-5 {width: 41.66667%;}
.grid-col-f-6 {width: 50%;}
.grid-col-f-7 {width: 58.33333%;}
.grid-col-f-8 {width: 66.66667%;}
.grid-col-f-9 {width: 75%;}
.grid-col-f-10 {width: 83.33333%;}
.grid-col-f-11 {width: 91.66667%;}
.grid-col-f-12 {width: 100%;}


.show_preview .adminlinks a {
  background: url('../images/icon-sprites.png') no-repeat #fff;
  background-position: 0 -1193px;
  display: inline-block;
  padding: 10px 20px 10px 45px;
  border-radius: 5px;
  margin: 10px 0;
  text-decoration: none;
  box-shadow: 0 3px 5px #ccc;
  color: #555;
}

.show_preview .adminlinks a:hover {
  background-color: $primary-color;
  color: #fff;
  background-position-y: -1260px;
}

/*header*/

.logged-in .header,
.not-front.not-logged-in .header:not(.not-front.not-logged-in.page-user .header):not(.not-front.not-logged-in.page-plma-user .header) {
  height: 112px;
  background: $header-background;
}

.header__wrapper {
  height: 112px;
}

.header__branding {
  height: 112px;
  width: 177px;
  overflow: hidden;
  -ms-flex-item-align: start;
    align-self: flex-start;
}

.logged-in #logo {
  display: block;
  position: relative;
  z-index: 550;
}

.header__site-name,
.not-front.not-logged-in .header__site-name {
  font-family: $font-family-poppins;
  font-weight: 800;
  font-size: 1.75rem;
  margin: 0;
  flex: 1 1 auto;
  padding-left: $spacer;
}

.logged-in .header__site-name {
  margin-bottom: 0;
}

.header__site-name a {
  color: $black;
  text-decoration: none;
  line-height: 1.2;
}

.not-logged-in .item-list-top ul {
  display: table;
  width: 100%;
  margin: 0;
}

.not-logged-in  form .form-item:not(.form-item-access-code) {
  margin-bottom: 10px;
}

.not-logged-in .login h3,
#plma-user-forgot-access-code h3 {
  font-size: 24px;
}

.not-logged-in .item-list-top ul li {
  display: table-cell;
  vertical-align: middle;
}

.header__user-menu {
  flex: 1 1 auto;
}

.header__user-menu .menu {
  display: table;
}

.header__user-menu .menu li {
  display: table-cell;
}

.header__user-menu .menu a {
  font-size: 0;
  display: block;
  width: 60px;
  height: 40px;
  line-height: 5rem;
  background: url('../images/icon-sprites.png') no-repeat;
}

.header__user-menu .menu a:hover {
  background-color: rgba(0,0,0,.1);
}

.header__user-menu .menu li a {
  background-repeat: no-repeat;
}

.header__user-menu .menu li:first-child a {
  background-position: top -48px center;
}

.header__user-menu .menu li:last-child a {
 background-position: top -180px center;
}

.not-logged-in .header__user-menu .menu li:last-child a {
 background-position: top -48px center;
}


.header__user-menu .region-header {
  -webkit-box-pack: end;
    -ms-flex-pack: end;
        justify-content: flex-end;
}

.header .login-info {
  font-size: 0.875rem;
  line-height: 1.4375rem;
  color: $black;
  margin-right: 1.25rem;
}

.header .login-info label {
  display: inline-block;
}

.header__user-menu li.leaf {
  list-style-image: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.actionbar {
  height: 72px;
  position: relative;
  z-index: 500;
  border-top: 1px solid $primary-color-dark;
  &__wrapper {
    position: relative;
  }
}

.date-location {
  font-family: 'avenir_next_lt_probold', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0;
}

#tertiary-menu {
  display: table;
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: table-cell;
    text-align: center;
    a {
      height: 72px;
      line-height: 72px;
      display: inline-block;
      padding: 0 15px;
      text-decoration: none;
      color: $black;
      &:before {
        margin-top: -0.5rem;
        padding-bottom: 2px;
      }
    }
    &.matchmaker a {
      @include svg-inline(calendar, before, $black, 1rem, 0.5rem);
      &:before {
        margin-top: -8px;
      }
    }
    &.favorites a {
      @include svg-inline(star, before, $black, 1rem, 0.5rem);
      &:before {
        margin-top: -12px;
      }
    }
    &.retailer_invite a {
      @include svg-inline(retailers, before, $black, 1.4rem, 0.5rem);
      &:before {
        margin-top: -8px;
      }
    }
    &.favorites a .favorites-count {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      background: $black;
      color: $white;
      border-radius: 50%;
      font-family: 'avenir_next_lt_probold', sans-serif;
      font-weight: normal;
    }
  }
}

.favorites-count-wrapper {
  display: inline-block;
  margin-left: 5px;
}

/*main menu*/
#main-menu {
  margin: 0;
  padding: 0;
}

#main-menu li {
  padding: 0;
}

.front .region-content {
  @extend .grid-layout;
  padding-top: $spacer*2;
}

.front .region-content,
.quick-links-menu {
	-webkit-box-pack: justify;
    -ms-flex-pack: justify;
      justify-content: space-between;
  & > .block {
    -webkit-box-flex: 0;
      -ms-flex: 0 auto;
          flex: 0 auto;
    width: 49%;
    margin-bottom: 2%;
    background-color: #ffffff;
    border-radius: 5px;
    -webkit-box-shadow: 2px 3px 3px #ccc;
    box-shadow: 2px 3px 3px #ccc;
    .content {
      display: table;
      width: 100%;
    }
    &:hover {
      background-color: $primary-color-dark;
      a {
        color: $white;
        text-decoration: none;
      }
      .menu-img {
        box-shadow: none;
      }
      .menu-items {
        @include svg-inline(chevron-right, before, $white, 1rem, 0);
        &:before {
          height: 1.3rem;
        }
      }
    }
  }
  #block-block-48,
  #block-block-49,
  #block-block-50 {
    background-color: $primary-color;
    a {
      color: $white;
    }
    .menu-items {
      @include svg-inline(chevron-right, before, $white, 1rem, 0);
      &:before {
        height: 1.3rem;
      }
    }
    &:hover {
      background-color: $white;
      a {
        color: $dark-gray;
      }
      .menu-items {
        @include svg-inline(chevron-right, before, $gray-500, 1rem, 0);
        &:before {
          height: 1.3rem;
        }
      }
    }
  }
  & > .block:nth-child(2n+0) {
    margin-right: 0;
  }
  .menu-img {
    display: table-cell;
    vertical-align: middle;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    box-shadow: 2px 3px 3px #ccc;
    a {
      display: block;
      height: 80px;
      width: 100px;
      padding: 10px 20px;
    }
  }
  .menu-items {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 80px;
    padding-left: 20px;
    font-family: 'avenir_next_lt_probold', sans-serif;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.3;
    padding: 0.9375rem;
    padding-right: 20px;
    position: relative;
    @include svg-inline(chevron-right, before, $gray-500, 1rem, 0);
    &:before {
      position: absolute;
      right: 1.5rem;
      top: 30px;
      height: 1.3rem;
    }
    a {
      color: $dark-gray;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  #block-block-8 {
    .menu-img a {
      @include svg-inline(exhibitor-matchmaker, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(exhibitor-matchmaker, before, $white, 60px, 0);
    }
  }

  #block-block-7 {
    .menu-img a {
      padding-left: 23px;
      @include svg-inline(product-menu, before, $primary-color, 60px, 0);
      &:before {
        width: 54px;
      }
    }
    &:hover .menu-img a {
      padding-left: 23px;
      @include svg-inline(product-menu, before, $white, 60px, 0);
      &:before {
        width: 54px;
      }
    }
  }
  #block-block-19 {
    .menu-img a {
      @include svg-inline(crosshairs-solid, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(crosshairs-solid, before, $white, 60px, 0);
    }
  }
  #block-block-6 {
    .menu-img a {
      @include svg-inline(speaker, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(speaker, before, $white, 60px, 0);
    }
  }
  #block-block-24 {
    .menu-img a {
      @include svg-inline(trade-show-fact, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(trade-show-fact, before, $white, 60px, 0);
    }
  }
  #block-block-37 {
    .menu-img a {
      @include svg-inline(trade-show-fact, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(trade-show-fact, before, $white, 60px, 0);
    }
  }
  #block-block-38 {
    .menu-img a {
      @include svg-inline(promote, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(promote, before, $white, 60px, 0);
    }
  }
  #block-block-41 {
    .menu-img a {
      @include svg-inline(buyers-list, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(buyers-list, before, $white, 60px, 0);
    }
  }
  #block-block-43 {
    .menu-img a {
      @include svg-inline(buyers, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(buyers, before, $white, 60px, 0);
    }
  }
  #block-block-44 {
    .menu-img a {
      @include svg-inline(newspaper-solid, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(newspaper-solid, before, $white, 60px, 0);
    }
  }
  #block-block-45 {
    .menu-img a {
      @include svg-inline(supermarket, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(supermarket, before, $white, 60px, 0);
    }
  }
  #block-block-46 {
    .menu-img a {
      @include svg-inline(supermarket, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(supermarket, before, $white, 60px, 0);
    }
  }
  #block-block-47 {
    .menu-img a {
      @include svg-inline(paperclip-solid, before, $primary-color, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(paperclip-solid, before, $white, 60px, 0);
    }
  }
  #block-block-48 {
    .menu-img a {
      @include svg-inline(leaf-solid, before, $white, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(leaf-solid, before, $primary-color, 60px, 0);
    }
  }
  #block-block-49 {
    .menu-img a {
      @include svg-inline(wine-glass-solid, before, $white, 60px, 0);
      &:before {
        background-position: center;
        background-size: 63%;
      }
    }
    &:hover .menu-img a {
      @include svg-inline(wine-glass-solid, before, $primary-color, 60px, 0);
      &:before {
        background-position: center;
        background-size: 63%;
      }
    }
  }
  #block-block-50 {
    .menu-img a {
      @include svg-inline(cannabis-solid, before, $white, 60px, 0);
    }
    &:hover .menu-img a {
      @include svg-inline(cannabis-solid, before, $primary-color, 60px, 0);
    }
  }
}

/* Footer */
.region-footer {
  p.social {
    display: flex;
    justify-content: center;
    align-items: center;
    a.icon-twitter {
      @include svg-icon-replace(twitter, #1d9bf0, #999, 1.5rem);
    }
    a.icon-linkedin {
      @include svg-icon-replace(linkedin-in, #0077b5, #999, 1.5rem);
    }
    a.icon {
      display: inline-block;
      background-color: #f0f0f0;
      background-size: 14px;
      border-radius: 100%;
      background-position: center;
    }
  }
}

/*stats*/
.stats {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 3px 3px #ccc;
  padding: 1.25rem;
}

.stats__item {
  display: flex;
}

.stats__exhibitor {
  color: $secondary-color;
}

.stats__npe {
  color: $primary-color-dark;
}

.stats__new-products {
  color: $tertiary-color;
}

.stats__icon {
  width: 62px;
  max-width: 62px;
  height: 62px;
  border-radius: 31px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.25rem;
  background-position-x: center;
  padding: 10px;
}

.stats__exhibitor .stats__icon {
  padding-left: 16px;
  @include svg-inline(exhibitors, before, $secondary-color, 42px, 0);
  background-color: $secondary-color-lighter;
  &:before {
    width: 30px;
  }
}

.stats__npe .stats__icon {
  padding-left: 19px;
  @include svg-inline(light-bulb, before, $primary-color-light, 42px, 0);
  background-color: #ceebe5;
  &:before {
    width: 24px;
  }
}

.stats__new-products .stats__icon {
  padding-left: 12px;
  @include svg-inline(product-menu, before, $tertiary-color, 42px, 0);
  background-color: $tertiary-color-lighter;
  &:before {
    width: 38px;
  }
}

.stats__content {
  display: table-cell;
  padding-left: 1.25rem;
  vertical-align: middle;
  border-left: 1px solid #e5e5e5;
}

.stats__number {
  font-family: 'avenir_next_lt_probold', sans-serif;
  font-weight: normal;
  font-size: 2.625rem;
  line-height: 2.875rem;
}

.stats__text {
  font-family: 'avenir_next_lt_probold', sans-serif;
  font-weight: normal;
  color: #76829b;
}

.form-item, .form-actions {
  margin-bottom: 0;
  margin-top: 0;
}

.dropit {
  list-style: none;
	padding: 0;
}

/*.dropit .dropit-trigger {
  position: relative;
}*/
#main-menu .dropit-trigger > a {
  padding: 0 0 0 15px;
  height: 66px;
  white-space: nowrap;
  line-height: 66px;
  display: inline-block;
  text-decoration: none;
  color: $black;
  &.active {
    color: $black;
  }
  @include svg-inline(bars-solid, before, $black, 1rem, 0.5rem);
  &:before {
    margin-top: -0.5rem;
  }
}

.dropit-trigger > a.selected {
  background-position-y: -566px;
}

.dropit .dropit-submenu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5000;
  margin: 0;
  display: none;
  text-align: left;
  min-width: 235px;
  list-style: none;
	padding: 10px;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0px 5px 10px 0px #e5e5e5;
  -moz-box-shadow: 0px 5px 10px 0px #e5e5e5;
  box-shadow: 0px 5px 10px 0px #e5e5e5;
}

.dropit .dropit-open .dropit-submenu {
  display: block;
  background: #fff;
}

.dropit .dropit-submenu li {
  display: block;
  a {
    padding: 10px 15px;
    display: block;
    text-decoration: none;
  }
}

/*promo*/
.promo {
  background: #ffffff;
  padding: 40px 0;
  border-top: 1px solid #e5e5e5;

  .block {
    width: 32.20339%;
    flex: 0 auto;
    &:last-child .promo-list {
      border-right: none;
    }

    .promo-list {
      width: 100%;
      max-width: 100%;
      padding-right: 20px;
      border-right: 1px solid #ccc;
      .promo-video {
        max-width: 100%;
        padding-bottom: $spacer;
      }
      .promo-image {
        width: 100%;
        margin: 7px 0 0;
        img {
          border-radius: 5px;
          width: 100%;
          height: auto;
        }
      }
      .promo-text {
        display: table-cell;
        vertical-align: top;
        padding-left: $spacer;
        padding-right: $spacer;
        h3 {
          margin-bottom: 5px;
        }
        p {
          line-height: 21px;
          font-size: 15px;
          color: #444;
        }
      }
    }
  }
}

div.messages {
  background-image: none !important;
  padding: 20px;
  box-sizing: border-box;
  margin: 10px 0;
  text-align: left;
  border-radius: 5px;
}

div.error {
  border-color: #f9c9bf;
}

div.messages ul {
  margin: 0 0 0 15px;
}

div.messages ul li {
  list-style-type: disc;
}

input::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input:-ms-input-placeholder {
  color: #999 !important;
}

ul.tabs a {
}

#mobile-menu, .slicknav_menu {
  display: none;
}

#mobile-menu {
  float: none !important;
  position: absolute;
  left: 0;
  background: #fff;
  right: 0;
  z-index: 1000;
  padding: 2px 0;
  box-shadow: 2px 3px 3px #ccc;
  -webkit-box-shadow: 2px 3px 3px #ccc;
}

.slicknav_btn { position: relative; display: block; vertical-align: middle; cursor: pointer; display: table; height: 50px; width: 46px; }
.slicknav_menu .slicknav_menutxt { display: none; }
.slicknav_menu .slicknav_icon { display: table-cell; vertical-align: middle; }
.slicknav_menu .slicknav_no-text { margin: 0 }
.slicknav_menu .slicknav_icon-bar { display: block; width: 1.125em; height: 0.125em; -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px; -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); margin: 0 auto; }
.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar { margin-top: 0.188em }
.slicknav_nav { clear: both }
.slicknav_nav ul,
.slicknav_nav li { display: block }
.slicknav_nav .slicknav_arrow { font-size: 0.8em; margin: 0 0 0 0.4em; }
.slicknav_nav .slicknav_item { cursor: pointer; }
.slicknav_nav .slicknav_row { display: block; }
.slicknav_nav a { display: block }
.slicknav_nav a.active { color: #671113; }
.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a { display: inline }
.slicknav_menu:before,
.slicknav_menu:after { content: " "; display: table; }
.slicknav_menu:after { clear: both }
/* IE6/7 support */
.slicknav_menu { *zoom: 1 }
/*
User Default Style
Change the following styles to modify the appearance of the menu.
*/
.slicknav_menu {
font-size:16px;
}
/* Button */
.slicknav_btn {
}
/* Button Text */
.slicknav_menu .slicknav_menutxt {
}
/* Button Lines */
.slicknav_menu .slicknav_icon-bar {
background-color: #f5f5f5;
}

.slicknav_btn.slicknav_open .slicknav_icon-bar {
  background-color: #b64122;
}

.slicknav_menu {
}
.slicknav_nav {
color:#fff;
margin:0;
padding:0;
font-size:0.875em;
}
.slicknav_nav, .slicknav_nav ul {
list-style: none;
overflow:hidden;
}
.slicknav_nav ul {
padding:0;
margin:0 0 0 20px;
}
.slicknav_nav .slicknav_row {
padding:5px 10px;
margin:2px 5px;
}
.slicknav_nav a{
padding:5px 10px;
margin:2px 5px;
text-decoration:none;
}
.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a {
padding:0;
margin:0;
}
.slicknav_nav .slicknav_row:hover {
-webkit-border-radius: 6px;
-moz-border-radius: 6px;
border-radius: 6px;
background:#006da7;
color:#fff;
}
.slicknav_nav a:hover{
-webkit-border-radius: 6px;
-moz-border-radius: 6px;
border-radius: 6px;
background:#fff;
color: #3866b8;
}
.slicknav_nav .slicknav_txtnode {
margin-left:15px;
}

a.slicknav_open {
  background: #fff;
}
.head-wrapper {
  background: #671113;
  font-family: 'robotoregular';
}

/* User Menu */
#block-system-user-menu ul {
  margin: 0;
}

.view-find-exhibitors .view-header,
.view-see-new-products-by-category .view-header,
.view-view-registered-retailers .view-header {
  border-bottom: 1px solid #d5d0c4;
  color: #555;
  font-size: 0.9em;
  font-weight: normal;
  margin-bottom: 1em;
  padding: 0 0.5em 1em 0em;
  box-sizing: border-box;
  min-height: 33px;
}

.view-see-new-products-by-category .views-label-title,
.view-see-new-products-by-category .views-field-title h4 {
  display: inline-block;
}

.view-see-new-products-by-category .views-label-title {
  font-family: 'avenir_next_lt_probold';
}

.view-see-new-products-by-category h4 {
  margin: 0;
}

.view-see-new-products-by-category .views-field-title {
  text-transform: uppercase;
}

.view-see-new-products-by-category .views-field-field-product-qualifiers ul {
  margin: 0;
  padding: 0;
}

.product_announced_details .product_qualifiers li ,
.view-see-new-products-by-category .views-field-field-product-qualifiers li {
  cursor: default;
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  background: #fff;
  border: 1px solid #e5e5e5;
  margin: 0 4px 4px 0;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 30px;
}

.product_announced_details .product_category {
  background-color: #f0f0f0;
  border-radius: 30px;
  padding: 0 10px;
  font-family: 'avenir_next_lt_probold';
  font-weight: normal;
  margin: 5px 0;
  font-size: 14px;
  display: inline-block;
}

.view-find-exhibitors .views-row.is-pro {
  background: #f5f5f5;
  padding: 1em;
  margin-bottom: 0;
  box-sizing: border-box;
}

.view-find-exhibitors .views-row.is-pro .views-field-field-country::before {
  content: 'Ad';
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #145b88;
  color: #145b88;
  font-size: 14px;
}

.view-find-exhibitors .views-row.is-pro-last {
  margin-bottom: 1em;
  border-bottom: 0;
}

.view-find-exhibitors .views-row.is-pro .notification.advertisement {
  display: none;
}

.view-find-exhibitors .views-row.is-pro div.pro {
  cursor: default;
  display: table-cell;
  font-size: 14px;
  color: #007274;
}

.view-find-exhibitors .views-row.is-pro div.pro span {
  border: 1px solid #007274;
  border-radius: 4px;
  padding: 0 8px;
  margin-right: 5px;
}

/** Popup **/
#modal-popup,
#modal-popup-download,
#share-calendar-popup {
  background: #f5f5f5;
  margin: 8px auto;
  max-width: 650px;
  padding: 32px;
  position: relative;
  text-align: left;
}

.mfp-bg {
  opacity: .6;
}

#modal-popup h1,
#modal-popup-download h1,
#share-calendar-popup h1 {
  color: #444;
  background: #fff;
  margin: -32px -32px 24px -32px;
  padding: 24px 64px 24px 32px;
  font-size: 30px;
  line-height: 1;
  border-bottom: 1px solid #e3e3e3;
}

#modal-popup div.messages,
#modal-popup-download div.messages,
#share-calendar-popup div.message{
  margin: 0 0 1em 0;
}

#modal-popup input.form-text,
#share-calendar-popup input.form-text {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

#modal-popup input.form-submit,
#share-calendar-popup input.form-submit {
/*  width: 100%;
*/  box-sizing: border-box;
  text-transform: uppercase;
  padding: 10px 30px;
  white-space: normal;
}

#modal-popup button.mfp-close,
#modal-popup-download button.mfp-close,
#share-calendar-popup button.mfp-close {
  text-indent: -9999em;
  background: url('../images/icon_close_popup.png') no-repeat 50% 50%;
  top: 18px;
  right: 16px;
}

#modal-popup .form-item-receieve-appointments {
  color: #444;
  margin-bottom: 1em;
}

#modal-popup .form-item-notification-email label {
  display: block;
  font-weight: normal;
  font-style: italic;
}

#modal-popup div.form-type-checkbox label {
  display: block;
  padding: 0;
  margin-bottom: 0 !important;
  height: auto;
  padding: 10px 20px 10px 50px;
  line-height: 24px;
}

#modal-popup div.form-type-checkbox.form-item-receieve-appointments {
  margin: 1em 0;
  padding: 0;
  background: #f5f5f5;
  border: 1px solid #dedede;
}

#modal-popup .form-item-receieve-appointments label {
  font-family: 'robotoregular' !important;
}

#modal-popup div.form-item input[type="checkbox"] {
  display: none;
}

#modal-popup div.form-item input[type="checkbox"] + label {
  border: 1px solid #eee;
  background: #fff;
}

#modal-popup div.form-item input[type="checkbox"] + label::before {
  bottom: 0;
  left: 15px;
}

#modal-popup div.form-item input[type="checkbox"]:checked + label {
  background-color: #465b96;
  background-position:10px 14px;
  color: #fff;
}

#modal-popup #edit-receive-appointments-alert {
  color: #8b0000;
  margin-top: 1em;
}

#modal-popup #edit-receive-appointments-alert p {
  color: inherit;
}

/*Modal List*/
#modal-popup .chosen-container-multi .chosen-choices {
	padding: 5px 10px 5px 10px;
	border-color: #dedede;
	background: #fff;
	font-size: 15px;
}

#modal-popup .chosen-container .chosen-results li {
  padding: 10px;
}

#modal-popup .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
	width: auto !important;
}

#modal-popup form .chosen-container-multi .chosen-choices li.search-choice {
	background: #913343;
	border-radius: 30px;
	color: #fff;
	border: none;
	padding: 8px 25px 8px 10px;
	margin: 0 5px 0 0;
}

#modal-popup .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
	top: 10px;
	right: 5px;
	background: url('../images/favorite-chosen-sprite.png') no-repeat -42px 1px;
}

#modal-popup .form-textarea-wrapper textarea {
	border: 1px solid #dedede;
	padding: 10px;
	font-size: 15px;
}

/*Share Calendar*/
.page-view-appointments a#calendar-share-btn {
  background: url('../images/share-icon.png') no-repeat #fff 10px center;
  background-size: 15px;
  padding-left: 35px;
}

#share-calendar-popup .ui-tabs .ui-tabs-panel {
  padding: 16px 0 0 0;
}

#share-calendar-popup .ui-widget {
  font-family: 'robotoregular';
  font-weight: normal;
  font-size: 16px;
}

#share-calendar-popup h1 {
  margin-bottom: 0;
}

#share-calendar-popup .ui-tabs {
  padding: 0;
  border: none;
  background: transparent;
}

#share-calendar-popup li.ui-state-default {
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0 10px;
  background: none;
}

#share-calendar-popup ul.ui-tabs-nav {
  margin: 0 -32px;
  padding: 0 32px;
  background: #fff;
  border: none;
  border-radius: 0;
}

#share-calendar-popup ul.ui-tabs-nav li a {
  text-transform: uppercase;
  color: #929292;
  font-size: 15px;
  padding: 15px 0 15px 30px;
  outline: 0;
}

#share-calendar-popup li.ui-state-default a {
  background: url('../images/share-calendar-icon.png') no-repeat;
  background-size: 20px;
}

#share-calendar-popup ul.ui-tabs-nav li:last-child {
  margin-right: 0;
}

#share-calendar-popup li.ui-tabs-active {
  background: #f5f5f5;
}

#share-calendar-popup ul.ui-tabs-nav li.ui-tabs-active a {
  color: #404040;
}

#share-calendar-popup ul li#subscribe-tab a {
  background-position: 0px -36px;
}

#share-calendar-popup ul li#share-tab a {
  background-position: 0px -143px;
}

#share-calendar-popup ul li#download-tab a {
  background-position: 0px -247px;
}

#share-calendar-popup ul.ui-tabs-nav li#subscribe-tab.ui-tabs-active a {
  background-position: 0px 18px;
}

#share-calendar-popup ul.ui-tabs-nav li#share-tab.ui-tabs-active a {
  background-position: 0px -89px;
}

#share-calendar-popup ul.ui-tabs-nav li#download-tab.ui-tabs-active a {
  background-position: 0px -194px;
}

#share-calendar-popup div.messages {
  padding: 10px !important;
  margin-bottom: 10px;
}

#share-calendar-popup #tab-subscribe #cal-subscription-link {
  background: #fff;
  height: 50px;
  line-height: 50px;
  border: 1px solid #dedede;
  margin: 20px 0 0 0;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 75%;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#share-calendar-popup #tab-subscribe .calender-subscribe-btn {
  float: right;
  width: 24%;
  background: #27529f;
  height: 50px;
  line-height: 50px;
  margin: 20px 0 0 0;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-family: 'robotobold';
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
}

#share-calendar-popup #tab-subscribe .calender-subscribe-btn:hover {
  background: #D27200;
}

#share-calendar-popup #tab-subscribe #msg-copied {
  clear: left;
  text-align: right;
  color: #58a958;
  font-family: 'robotobold';
  font-weight: normal;
  background: url('../images/check.png') no-repeat right;
  padding-right: 20px;
  background-size: 16px;
}

#share-calendar-popup .tagsinput {
  min-height: auto !important;
  height: auto;
  padding: 5px 5px 0px 5px;
  margin-bottom: 10px;
  border-color: #dedede;
}

#share-calendar-popup #tab-share label {
  margin-bottom: 5px;
}

#share-calendar-popup .tagsinput span.tag {
  border: none;
  border-radius: 30px;
  background: #913343;
  color: #fff;
  font-family: 'robotoregular';
  font-weight: normal;
  font-size: 15px;
}

div.tagsinput input {
  font-family: 'robotoregular';
  font-weight: normal;
}

#share-calendar-popup div.tagsinput span.tag a {
  color: #fff;
  display: inline-block;
  margin-top: -5px;
  vertical-align: middle;
  margin-right: 5px;
}

#share-calendar-popup textarea {
  border: 1px solid #dedede;
  font-size: 15px;
  padding: 10px;
}

#share-calendar-popup #tab-download a {
  display: block;
  height: 50px;
  line-height: 50px;
  background: #3866b8;
  color: #fff;
  text-transform: uppercase;
  margin: 16px auto 0 auto;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
}

#share-calendar-popup #tab-download a:hover {
  background: #D27200;
}

span.warning-icon {
  background: url('../images/icon_warning.png') no-repeat 0 50%;
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: text-bottom;
  padding: 0 4px 0 0;
}

.hyphenate {
  -ms-word-break: -all;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.jqmWindow .dialog {
	padding: 20px;
	background: #fff;
}

.dialog  .dialog-header {
	background: #fff;
	border-bottom: 1px solid #ccc;
	padding: 20px;
	margin-bottom: 20px;
}

.dialog  .dialog-header h1 {
	margin: 0;
}

.dialog .dialog-body {
	padding: 0 20px;
}

.jqmWindow .jqmClose {
  height: auto;
  padding: 10px;
  background: #0b846a;
  color: #ffffff;
  border: none;
}

.item-list .pager {
  font-size: 0;
  margin: 10px 0;
}

.item-list .pager li {
  display: inline-block;
  font-size: 16px;
  padding: .5em .75em;
  margin: 2px 2px 2px -4px;
}

// Iframe Ratio
.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: var(--#{$variable-prefix}aspect-ratio);
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@each $key, $ratio in $aspect-ratios {
  .ratio-#{$key} {
    --#{$variable-prefix}aspect-ratio: #{$ratio};
  }
}

// Trade Publications
.view-trade-publications {
  ul {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    margin: 0;
    list-style-type: none;
    justify-content: center;

    li {
      width: 25%;
      padding: 10px;
      text-align: center;
      margin-bottom: 10px;

      @media only screen and (max-width: 969px) {
        width: 33.33333%;
      }

      @media only screen and (max-width: 639px) {
        width: 50%;
      }

      @media only screen and (max-width: 425px) {
        width: 100%;
      }

      &::marker {
        content: none;
      }

      img {
        border-radius: 10px;
        box-shadow: 0px 3px 3px #ccc;
        margin-bottom: 5px;
        height: auto;
      }
    }
  }

  .views-field-field-country {
    font-style: italic;
    font-size: 15px;
  }

  .views-field-title {
    font-family: "avenir_next_lt_probold";
    line-height: 1.2;
  }

  .views-field-field-publication-link a {
    border-radius: 30px;
    background: $white;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px 20px 2px 20px;
    font-size: 14px;
    box-shadow: 0px 3px 3px #ccc;
    margin-top: 5px;

    &:hover {
      background-color: $primary-color-dark;
      color: $white;
      text-decoration: none;
    }
  }
}
