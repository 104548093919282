@media only screen and (max-width: 639px) {
  html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  body {
    font-size: .9em;
    line-height: 22px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  .container {
    padding: 0 15px;
  }

  #header {
    margin-top: 38px;
  }

  #block-system-user-menu,
  #show-dates,
  .front.logged-in #highlighted,
  .logged-in .login-info,
  .header__user-menu,
  .hide-mobile {
    display: none;
  }

  .front.not-logged-in #page-background {
    background: #007f9d;
  }

  .front.not-logged-in #name-and-slogan {
    padding-bottom: 135px;
  }

  .front.not-logged-in .branding {
    margin-top: -90px;
  }

  .not-front.not-logged-in #header-background, .not-front.logged-in #header-background {
    background-color: transparent;
  }

  #block-locale-language {
    float: none;
  }

  .head-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 12;
  }

  .logged-in .header,
  .not-front.not-logged-in .header {
    height: 50px;
  }

  .not-logged-in.page-plma-user .header,
  .not-logged-in.page-user .header {
    height: auto;
  }

  .not-logged-in .login-screen .grid-col-f-6 {
    width: 100%;
  }

  .header__wrapper {
    height: 50px;
  }

  .logged-in .header__branding,
  .not-front.not-logged-in .header__branding {
    height: 50px;
    order: 1;
    width: 100px;
    display: flex;
    align-items: center;
    background: url('http://showpreview.localhost:8000/sites/all/themes/plma_responsive/logo.png') no-repeat;
    background-size: cover;
  }

  .not-front.logged-in .header__branding,
  .not-front.not-logged-in .header__branding {
    width: 8.33333%;
  }

  .not-front.logged-in #logo {
    padding: 0;
    position: relative;
    height: 50px;
  }

  .logged-in .header__site-name,
  .not-front.not-logged-in .header__site-name {
    order: 2;
    width: 50%;
    font-size: 17px;
    margin-bottom: 0;
    padding-left: 10px;
  }

  .not-front.logged-in .header__site-name,
  .not-front.not-logged-in .header__site-name {
    width: 83.33333%;
    text-align: center;
  }

  .not-front.not-logged-in .header__site-name {
    text-transform: initial;
  }

  .logged-in #logo img {
    height: auto;
    opacity: 0;
    vertical-align: middle;
  }

  .slicknav_menu {
    display: block;
    order: 3;
    width: 8.33333%;
  }

  .front.logged-in .head-wrapper {
    position: relative;
    margin-bottom: -38px;
  }

  .front.logged-in .branding {
    position: relative;
    z-index: 12;
  }

  .logged-in #main-wrapper {
    margin-top: 20px;
  }

  .logged-in #page-title {
    margin-bottom: 20px;
  }

  #block-plma-user-access-user-login-access .messages.error a {
    color: inherit;
  }

  .region-s-date p {
    color: #fff000;
  }

  .messages.error {
    margin-top: 15px;
    padding: 20px !important;
  }

  .not-front.logged-in #logo img {
    display: none;
  }

  .front.logged-in #logo img {
    width: 85px;
    margin: 0;
  }

  .not-front.logged-in #logo::before {
    content: '';
    width: 38px;
    height: 38px;
    background: url('../images/home-ico.png') no-repeat center left;
    background-size: 18px 15px;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .not-front.logged-in .branding,
  .not-front.not-logged-in .branding {
    margin-top: 0;
    padding-bottom: 0;
  }

  .stats {
    background: transparent;
    padding: 0;
    box-shadow: none;
  }

  .stats__item {
    width: 100%;
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 3px 3px #ccc;
      -webkit-box-shadow: 2px 3px 3px #ccc;
  }

  .stats__number {
    font-size: 28px;
    line-height: 1.2;
  }

  #tertiary-menu {
    width: 100%;
  }

  #tertiary-menu a,
  #tertiary-menu li:last-child {
    border: none;
  }

  #tertiary-menu li:first-child {
    border-right: 1px solid #e5e5e5;
  }

  #tertiary-menu li:only-child {
    border: none;
  }

  .quick-links-menu > .block {
    width: 100%;
    background-position: right -10px top -1877px;
  }

  .quick-links-menu .menu-items {
    font-size: 15px;
    padding: 8px 20px 8px 10px;
  }

  .quick-links-menu .menu-img img {
    max-width: 100px;
  }

  #navigation .section {
    display: table;
    width: 100%;
    background: #fff;
  }

  #navigation ul#tertiary-menu {
    display: table;
    float: none;
    vertical-align: top;
    width: 100%;
    background: #fff;
  }

  #navigation #tertiary-menu li a {
    padding: 0 5px;
    white-space: nowrap;
    font-size: 13px;
  }

  #navigation ul#notifications-menu {
    width: 1%;
    vertical-align: top;
    display: table-cell;
    float: none;
    text-align: center;
  }

  #notifications-menu li.notifications > a {
    border-right: none;
  }

  #matchmaker-requests-container {
    top: 51px;
  }

  #matchmaker-requests-list {
    text-align: left;
  }

  #navigation ul#tertiary-menu.clearfix:after,
  #navigation .section.clearfix:after {
    content: none;
  }

  #navigation ul#tertiary-menu li {
    display: table-cell;
    width: 50%;
    text-align: center;
    background: #fff;
    border-right: 1px solid #ccc;
  }

  #tertiary-menu li.matchmaker_notifications {
    width: 40px;
  }

  #navigation ul li:last-child {
    border: none;
  }

  #user-access-form .item-list ul li.first {
    font-weight: bold;
  }

  a#h-contact {display: none;}

  #welcome-text span {
    font-size: 16px;
    color: #fff;
    display: block;
    font-weight: normal;
  }

  #header-image {
    display: none;
  }

  #navigation #menu {
    background: #526d7f;
    display: block;
    width: 45px;
    height: 45px;
  }

  #page-menu {
    display: none;
  }

  .dropit .dropit-submenu li a {
    color: #000;
  }

  #site-name a {
    font-size: 20px;
    line-height: 20px;
  }

  .not-logged-in .header__site-name {
    font-size: 38px;
    line-height: 32px;
  }

  .front.not-logged-in #site-name a {
   font-size: 28px !important;
  }

  .not-front.logged-in #header .col {
    text-align: center;
  }

  .front.not-logged-in #show-dates {
    margin: 0;
  }

  .not-front.not-logged-in #show-dates {
    display: none;
  }

  .front.not-logged-in #show-dates .meet-people {
    font-size: 24px;
  }

  .not-logged-in .login-screen {
    width: 95%;
  }

  .front.not-logged-in #footer {
    display: none;
  }

  #site-slogan {
    display: none !important;
  }

  .front.logged-in #site-slogan {
    display: block;
    font-size: 14px;
  }

  .not-front.not-logged-in #page-title {
    text-align: center;
  }

  .front #page-title {
    font-size: 20px;
  }

  form .form-managed-file {
    padding: 10px;
  }

  .npe-form-content-wrapper .npe-form-intro .info,
  .npa-form-content-wrapper .npa-form-intro .info,
  .npe-form-content-wrapper .npe-form-intro img,
  .npa-form-content-wrapper .npa-form-intro img {
    display: block;
    width: 100%;
  }

  .page-node .node-page .field-name-body .speaker-group .center img {
    margin: 0 10px 0 0;
  }

  .page-node .node-page #map img {
    float: none;
    margin: 0;
    width: inherit;
  }

  #speakers-seminars .speaker-profile {
    padding: 15px;
  }

  .tradeshowfacts_wrapper table {
    width: 100%;
  }

  .front.not-logged-in #content {
    margin-top: 105px;
    margin-bottom: 0;
  }

  .front.logged-in #content {
    margin: 0 0 5px 0;
  }

  .not-logged-in .header__show-date {
    margin-top: 20px;
  }

  .not-logged-in .header__show-date::before {
    top: -10px;
  }

  #footer {
    font-size: 12px;
  }

  .front #content .region-content .block,
  .front #sidebar-first .region-sidebar-first .block {
    width: 100%;
  }

  .front.logged-in #sidebar-first {
    display: none;
  }

  .front #content .region-content .block .content {
    background-position: 98%;
    margin-bottom: 0;
  }

  .front #content .region-content .block .menu-img a {
    display: block;
    margin-right: 10px;
    font-size: 0;
    line-height: 0;
  }

  .front #content .region-content .block .menu-img a img {
    height: 40px;
    width: 56px;
  }

  .front #content .region-content .block .content {
    display: table;
    width: auto;
  }

  .front #content .region-content .block .content .menu-img  {
    display: table-cell;
    vertical-align: middle;
  }

  .front #content .region-content .block .content p  {
    margin: 0;
  }

  .front #content .region-content .block .content .menu-items a  {
    padding: 5px 0;
  }

  .menu-items p {
    margin: 0;
    padding: 0;
  }

  .front.not-logged-in #logo-ribbon {
    display: none;
  }

  #block-block-25 {
    display: none;
  }

  #welcome-text, #date-text {
    display: block;
  }

  #block-plma-user-access-user-login-access {
    background: none;
    border: none;
    padding: 0;
    width: auto;
    text-align: center;
    color: #333;
  }

  .front.not-logged-in .login p {
    display: none;
  }

  #block-plma-user-access-user-login-access > h2 {
    display: none;
  }

  #block-plma-user-access-user-login-access .form-item-remember-me {
    display: none;
  }

  #block-plma-user-access-user-login-access .show-floor-navigator .item-list-top {
    display: none;
  }

  #block-plma-user-access-user-login-access .show-floor-navigator .item-list-bottom {
    display: block;
  }

  #node-6748 h2, #node-6748 h3 {
    text-align: center;
  }

  #node-6211 p img, #node-6748 p img, #node-7828 p img, #node-8825 p img {
    float: none !important;
    margin: 0 auto 1em auto !important;
    display: block;
    text-align: center;
  }

  .not-logged-in div.messages {
    margin: 0 auto .5em auto;
  }

  .promo .block {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .promo .block:last-child {
    border: none;
  }

  .promo-list {
    border: none;
    padding: 0;
  }

  .promo-image {
    width: 80px;
    display: table-cell;
  }

  .promo-text {
    display: table-cell;
    padding-left: 15px;
  }

  #announce-new-product-form label {
    float: none;
    display: block;
    width: auto;
  }

  #announce-new-product-form .form-item .description {
    margin-left: 0 !important;
  }

  #announce-new-product-form div.form-textarea-wrapper {
    width: auto;
    float: none;
  }

  #announce-new-product-form input.form-text,
  #announce-new-product-form select.form-select,
  #announce-new-product-form textarea.form-textarea {
    width: 100% !important;
  }

  form#announce-new-product-form {
    padding: 15px;
  }

  .npe-form-content-wrapper, .npa-form-content-wrapper {
    padding: 15px;
  }

  .announce-new-product-form .form-item-announced-product-npe-consideration,
  .announce-new-product-form .form-item-announced-product-consideration {
    margin: 0 -15px -15px -15px;
    padding: 15px;
  }

  #announce-new-product-form div.form-item input[type="checkbox"] + label  {
    background-position: 10px -166px;
  }

  #announce-new-product-form div.form-item input[type="checkbox"]:checked + label {
    background-position:10px 10px;
  }

  .form-item-announced-product-npe-agree-rules input[type="checkbox"] + label {
    background-position: 0 -166px !important;
  }

  .form-item-announced-product-npe-agree-rules input[type="checkbox"]:checked + label {
    background-position: 0 10px !important;
  }

  .form-item-announced-product-npe-innovative-features div.form-checkboxes, .form-item-announced-product-npe-innovation-benefits div.form-checkboxes {
    padding: 10px;
  }

  .previous-submissions-toggle {
    padding: 20px;
    background-position-y: -1317px;
  }

  .previous-submissions-toggle.expand {
    background-position-y: -1385px;
  }

  form label {
    margin-bottom: 5px;
  }

  .product_announced_title {
    font-size: 18px;
  }

  .form-item-announced-product-npe-new-as-private-label div.form-checkboxes {
    padding: 10px;
  }

  #announce-new-product-form div.form-type-checkbox label {
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }

  #announce-new-product-form .form-item-announced-product-consideration label, #announce-new-product-form .form-item-announced-product-npe-consideration label {
    padding: 10px 10px 10px 55px;
    line-height: 22px;
  }

  div.submit_announce_new_product {
    padding: 0;
    margin: 0;
  }

  div.submit_announce_new_product input#edit-submit {
    width: 100%;
  }

  #views-exposed-form-find-exhibitors-page .views-exposed-widget.views-submit-button .form-item {
    background: #f0f0f0;
    padding: 10px;
    border-radius: 3px;
    margin-top: 1px;
  }

  form#webform-client-form-30777 input[type="text"],
  form#webform-client-form-30777 input[type="email"] {
    width: 96%;
    padding: 2%;
  }

  .view-see-new-products-by-category .view-filters {
    margin-top: -1.75em;
  }

  .float-left {
    float: none;
    margin-right: 0;
    margin-bottom: .5em;
    text-align: center;
  }

  .float-right {
    float: none;
    margin-left: 0;
    margin-bottom: .5em;
    text-align: center;
  }

  #block-views-exp-find-exhibitors-page {
    clear: both;
  }

  #inner_advert_div {
    float: none;
    max-width: auto;
    margin: -10px auto 0 auto;
  }

  .exinfo.has_advertisement {
    float: none;
    width: auto;
    margin-top: 1em;
  }

  .company_info_div .booth_num_wrapper {
    display: block;
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }

  .company_info_div .booth_num_wrapper div {
    display: block;
    height: auto;
    margin: 5px 0;
    text-align: center;
    white-space: inherit;
  }

  .booth_num_wrapper div.map-view a {
    text-align: center;
    margin-top: 10px;
    display: block;
  }

  .company_heading {
    clear: both;
  }

  div.product_image {
    display: block;
    width: 100%;
    text-align: center;
  }

  .product_content {
    display: block;
    padding: 0;
  }

  div.product_announced_wrapper {
    padding: 0;
  }

  .member_info {
    display: block;
  }

  .member_info .member_info_row {
    display: block;
  }

  .member_info .member_info_col {
    display: block;
    white-space: normal;
    padding: 0;
    text-align: left;
    margin: .5em 0;
  }

  .member_info .member_info_val {
    display: block;
    width: auto;
    padding: 0;
  }

  #wrapper #first table#links {
    width: 100% !important;
    margin-bottom: 1em;
  }

  #wrapper #first table.links-left {
    float: none !important;
  }

  #wrapper #first table.links-right {
    float: none !important;
  }

  div.table-calendar table thead .icon {
    display: block;
    margin: 0 auto;
  }

 .appointment_detail_content table {
    overflow-x: auto;
    display: block;
  }

  div.table-calendar table thead th {
    font-size: 16px;
    text-align: center;
  }

  .appointment_detail_content .fields {
    padding: 10px;
  }

  div.appointment_detail_content .fields .name {
    text-align: left;
  }

  div.appointment_detail_content .fields .name,
  div.appointment_detail_content .fields .details {
    display: block;
    width: 100%;
    padding: 0px;
  }

  .appointment_detail_content table th, .appointment_detail_content table td {
    padding: 10px;
  }

  div.appointment_detail_wrapper {
    margin-top: -15px;
  }

  div.appointment_detail_wrapper .appointment_detail_content {
    display: block;
  }
  div.appointment_detail_wrapper .fields {
    display: block;
  }

  div.appointment_detail_wrapper .name {
    display: block;
    text-align: left;
  }

  div.appointment_detail_wrapper .details {
    display: block;
  }

  .appointment_detail_table .c1 {
    display: block;
    text-align: left;
  }

  .appointment_detail_table .c2 {
    display: block;
  }

  #modal-popup h1,
  #modal-popup-download h1,
  #share-calendar-popup h1 {
    font-size: 22px;
  }

  #modal-popup button.mfp-close,
  #modal-popup-download button.mfp-close {
    top: 12px;
  }

  #page.minimal #block-block-25, #page.minimal #block-block-27, #page.minimal #block-locale-language {
    display: none;
  }

  #page.minimal #header-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 46px;
    height: 38px;
    overflow: hidden;
    width: auto;
    z-index: 13;
    border-bottom: none;
  }

  #page.minimal #header {
    margin-top: 0;
  }

  #page.minimal #site-slogan {
    display: none;
  }

  .front.logged-in #page.minimal #logo img, .not-front #page.minimal #logo img {
    margin: 5px 10px 5px 5px;
    height: 28px;
    width: 28px;
  }

  #page.minimal #actionbar {
    margin-top: 38px;
    border-top: 4px solid #d8be1e;
  }

  .filter-and-clear {
    font-size: 13px;
  }

  .page-map .actionbar {
    display: none;
  }

  .page-view-exhibitors #block-system-main .content,
  .page-view-retailers #block-system-main .content,
  .page-favorites #block-system-main .content,
  .page-see-new-products #block-system-main .content {
    padding: 15px;
  }

  .page-view-exhibitors form .form-type-checkbox label {
    font-size: .9rem;
  }

  .view-find-exhibitors .views-field-field-product {
    line-height: 22px;
  }

  .view-find-exhibitors .views-row .col1 {
    position: inherit;
    float: left;
    width: 45px;
    margin: 0 5px 0 0;
  }

  .view-find-exhibitors .views-row .col2 {
    margin-left: 0;
  }

  .view-find-exhibitors .favorites a {
    font-size: 0;
  }

  .view-find-exhibitors .favorites .flag-throbber {
    display: none;
  }

  .view-find-exhibitors .favorites a.flag:before {
    margin-left: 0;
  }

  .view-find-exhibitors .schedule-appointment {
    display: block;
    text-align: center;
  }

  .flag-waiting .flag-throbber {
    display: none;
  }

  .views-label-field-country,
  .field-label-hall {
    display: none;
  }

  .secondary-speaker-group > h3 {
    margin: 0 !important;
  }

  .speaker-group ul {
    margin: 0;
  }

  .main-speaker-group ul,
  .speaker-group ul {
    display: block;
  }

  .speaker-group ul li {
    display: block;
    margin: 0 0 20px 0;
    width: 100%;
    text-align: left;
  }

  .speaker-group ul li.wide {
    width: 100%;
  }

  .speaker-group ul li span {
    display: block;
  }

  .speaker-group .speaker-group-info {
    text-align: center;
  }

  .speaker-group .spaker-group-info .name {
    font-family: 'robotobold';
    font-weight: normal;
  }

  .main-speaker-group ul {
    margin: 0;
  }

  .main-speaker-group ul li {
    display: block;
    margin: 0 0 20px 0;
    padding: 15px;
    width: 100%;
  }

  #speakers-seminars .speaker-profile.presenter img {
    width: 100%;
  }

  .main-speaker-group p {
    clear: both;
  }

  .main-speaker-group .time {
    padding-bottom: 0 !important;
  }

  .main-speaker-group li h2.nomargin {
    margin: -15px -15px 0 -15px;
  }

  .page-map #wrapper {
    padding: 15px;
  }

  .page-node .region-content:not(.node-type-webform .region-content) {
    padding: 15px;
  }

  #wrapper #first table#links td {
    white-space: normal !important;
  }

  .instructions, .node-type-webform .webform-client-form {
    padding: 15px;
  }

  .page-node .node-page .field-name-body h2,
  .page-node .node-page .field-name-body h3,
  .page-node .node-page .field-name-body h4,
  .page-node .node-page .field-name-body h5,
  .page-node .node-page .field-name-body h6 {
    text-align: left;
  }

  #webform-client-form-12135 .webform-component-textfield, #webform-client-form-12135 .webform-component-email {
    float: none;
    margin: 0;
    width: 100%;
  }

  #node-12135 #add-more {
    width: 100%;
    display: block;
  }

  #webform-client-form-12135 input[type="submit"], .webform-submit {
    width: 100%;
    display: block;
  }

  #node-12135 table.sticky-header {
    top: 38px !important;
  }

    /*hide "clear button" and "favorite shares by info" on favorite*/
  .page-favorites .views-exposed-form #edit-clear,
  .page-shared-favorites .views-exposed-form #edit-clear,
  div#shared-by-user-msg {
    display: none;
  }

  .page-favorites .views-exposed-form .views-exposed-widgets {
    padding: 0;
  }

  #share-calendar-popup li.ui-state-default {
    width: 100%;
    float: none;
    margin: 0;
  }

  #share-calendar-popup li.ui-state-default a {
    float: none;
    width: 100%;
    display: block;
  }

  #share-calendar-popup #tab-subscribe #cal-subscription-link {
    width: 100%;
    float: none;
  }

  #share-calendar-popup #tab-subscribe .calender-subscribe-btn {
    width: 100%;
    float: none;
    display: block;
    margin-top: 10px;
  }

  #modal-popup input.form-submit, #share-calendar-popup input.form-submit {
    width: 100%;
  }

  table.schedule, table.schedule tbody, table.schedule tr, table.schedule th, table.schedule td {
    display: block;
    width: auto !important;
  }

  table.schedule tr {
    clear: both;
  }

  table.schedule tr > td:last-child {
    border-bottom: 1px solid #f0f0f0 !important;
  }

  table.schedule td {
    padding: .5em 1em;
    box-sizing: border-box;
    float: left;
    width: 100% !important;
    border: none !important;
    text-align: left !important;
    white-space: normal !important;
  }

  .front.not-logged-in #page {
    min-height: 220px;
  }

  .front.not-logged-in #header-background {
    margin-top: -38px;
  }

  .instructions-col {
    display: block;
  }

  .instructions-col__left {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .instructions-col__right {
    width: 100%;
  }

  .page-appointment form .form-type-select {
    width: 100%;
  }

  #user-appointment-request-form fieldset,
  table.appointment-info,
  .exinfo {
    padding: 15px;
  }

  #edit-appointment-request-form .form-actions {
    width: 100%;
  }

  #edit-appointment-request-form input.form-submit,
  form#webform-client-form-30777 .form-submit[type="submit"],
  #edit-submit[type="submit"],
  div.member_appointment_form_div a,
  form#user-appointment-calender-matchmaker-settings-form .form-submit {
    width: 100%;
    margin-right: 0;
  }

  .page-explore-exhibitors .header__wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
      justify-content: space-between;
  }

  #edit-total-category-items, #edit-total-sub-category-items, #edit-total-product-items {
    padding: 20px;
  }

  .page-explore-exhibitors #logo {
    padding-top: 10px;
  }

  .page-explore-exhibitors #logo img {
    width: 85px !important;
    height: auto !important;
    margin: 0 !important;
  }

  .page-explore-exhibitors #page-title {
    font-size: 24px;
    margin: 20px 0;
  }

  .page-explore-exhibitors .company-info {
    display: block;
  }

  .page-explore-exhibitors .companies-count, .page-explore-exhibitors .register-link {
    display: block;
    width: 100%;
    padding: 20px;
  }

  .page-explore-exhibitors .views-exposed-form .views-exposed-widgets {
    padding: 20px;
  }

  .page-explore-exhibitors .companies-count h1 {
    font-size: 65px;
  }

  .secondary-speaker-group .profile-wrapper .workshop-profile {
    display: block;
    width: 100%;
  }

}

@media only screen and (max-width: 500px) {
  .not-logged-in .header__site-name {
    font-size: 28px;
  }
}

@media only screen and (max-width: 400px) {
  #tertiary-menu li {
    font-size: 0;
    width: 50%;
  }

  #tertiary-menu li a {
    position: relative;
  }

  #tertiary-menu li a::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  #tertiary-menu li.favorites a .favorites-count {
    font-size: 12px;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 4px;
  }

}
