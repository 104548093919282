@font-face {
  font-family: 'avenir_next_lt_probold';
  src: url('fonts/avenirnextltpro-bold-webfont.woff2') format('woff2'),
   url('fonts/avenirnextltpro-bold-webfont.woff') format('woff'),
   url('fonts/avenirnextltpro-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir_next_lt_proitalic';
  src: url('fonts/avenirnextltpro-italic-webfont.woff2') format('woff2'),
   url('fonts/avenirnextltpro-italic-webfont.woff') format('woff'),
   url('fonts/avenirnextltpro-italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir_next_lt_prolight';
  src: url('fonts/avenirnextltpro-light-webfont.woff2') format('woff2'),
   url('fonts/avenirnextltpro-light-webfont.woff') format('woff'),
   url('fonts/avenirnextltpro-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir_next_lt_promedium';
  src: url('fonts/avenirnextltpro-medium-webfont.woff2') format('woff2'),
   url('fonts/avenirnextltpro-medium-webfont.woff') format('woff'),
   url('fonts/avenirnextltpro-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir_next_lt_proregular';
  src: url('fonts/avenirnextltpro-regular-webfont.woff2') format('woff2'),
   url('fonts/avenirnextltpro-regular-webfont.woff') format('woff'),
   url('fonts/avenirnextltpro-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir_next_lt_prothin';
  src: url('fonts/avenirnextltpro-thin-webfont.woff2') format('woff2'),
   url('fonts/avenirnextltpro-thin-webfont.woff') format('woff'),
   url('fonts/avenirnextltpro-thin-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
