@media only screen and (max-width: 1140px) {
  .logged-in .header,
  .not-front.not-logged-in .header {
    height: 106px;
  }
  .header__wrapper {
    height: 106px;
  }
  .header__branding {
    height: 106px;
    width: 156px;
  }
  .actionbar {
    height: 40px;
  }
  .dropit-trigger > a {
    padding: 0 0 0 5px;
    height: 40px;
    line-height: 40px;
    font-size: 0.875rem;
  }
  #tertiary-menu li a {
    height: 40px;
    line-height: 40px;
    font-size: 0.875rem;
    padding: 0 5px;
  }
}

@media only screen and (max-width: 969px) {
  #header, #actionbar > .section, #breadcrumb, #main {
    width: auto !important;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 13px;
  }

  h6 {
    font-size: 11px;
  }

  /*hide*/
  .block-plma-user-access .form-item-remember-me,
  .block-plma-user-access .login h3,
  .header__site-slogan {
    display: none;
  }

  #header {
    background-position: right !important;
  }

  #header-image {
    display: none !important;
  }

  form input[type="text"],
  form input[type="password"],
  form input[type="email"],
  form textarea,
  form .container-inline-date input[type="text"],
  .form-type-select select {
    padding: 10px 20px;
  }

  .form-type-select select {
    background-position-y: -1460px;
  }

  #block-plma-user-access-user-login-access input[type="submit"] {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
  }

  #user-access-form .register-link-added li {
    text-align: center !important;
  }

  .not-logged-in .login-screen {
    box-shadow: none;
    width: 100%;
    max-width: none;
  }

  .not-logged-in #highlighted {
    width: 100%;
  }

  .not-logged-in .login-screen__top img {
    vertical-align: bottom;
  }

  .not-logged-in .login-screen__bottom {
    padding: 20px;
    align-items: center;
  }

  #user-access-form > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }

  #user-access-form .form-item,
  #user-access-form div:not(.form-item) {
    width: 100% !important;
  }

  #user-access-form .item-list-top {
    order: 2;
    margin-top: 15px;
    text-align: center;
    width: 100%;
  }

  #user-access-form .form-actions {
    order: 1;
  }

  .not-logged-in .item-list-top ul {
    display: block;
    margin: 0;
  }

  .not-logged-in .item-list-top ul li {
    display: block;
    margin-bottom: 10px;
  }

  .not-logged-in .item-list-top ul li:last-child {
    text-align: center;
    margin-bottom: 0;
  }

  .not-logged-in .form-actions input[type="submit"],
  #block-plma-user-access-user-login-access .show-floor-navigator #edit-submit {
    width: 100%;
    margin: 10px 0;
  }

  .not-front #header #logo,
  .logged-in #header #logo  {
    padding-left: 5px;
  }

  .not-logged-in .header {
    background: none;
  }

  .front.not-logged-in #show-dates {
    white-space: inherit;
  }

 .not-logged-in.page-node #show-dates {
    margin-left: 10px;
    display: none;
  }

  .front.not-logged-in #show-dates .eventDate {
    font-size: 20px;
    padding-right: 10px;
    display: inline-block;
  }

  .front.not-logged-in #show-dates .city {
    margin: 0px;
    display: inline-block;
    font-size: 20px;
  }

  .logged-in .login-info {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 320px;
    max-width: 350px;
    padding-right: 10px;
  }

  .header__user-menu {
    display: none;
    width: 25%;
  }

  .not-logged-in.page-plma-user .header {
    height: auto;
  }

  .logged-in .header,
  .not-front.not-logged-in .header,
  .header__wrapper,
  .header__branding,
  .header__user-menu .menu a {
    height: 60px;
  }

  .header__branding {
    width: 120px;
  }

  .header__user-menu .menu li:first-child a {
    background-position-y: -40px;
  }

  .header__user-menu .menu li:last-child a {
    background-position-y: -172px;
  }

  .logged-in #logo img,
  .not-front.not-logged-in #logo img:not(.not-logged-in.page-user #logo img):not(.not-logged-in.page-plma-user #logo img) {
    height: 60px;
  }

  .header .login-info {
    display: none;
  }

  .logged-in .header__site-name,
  .not-front.not-logged-in .header__site-name {
    width: 50%;
    font-size: 24px;
  }

  .slicknav_menu {
    display: block;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    width: 8.33333%;
  }

  .actionbar,
  #tertiary-menu a,
  .dropit-trigger > a {
    height: 50px;
    line-height: 50px;
  }

  #tertiary-menu li a {
    padding: 0 5px;
    font-size: 0.8rem;
  }

  #tertiary-menu li.matchmaker a {
    background-position-y: -245px;
  }

  #tertiary-menu li.matchmaker a {
    background-position-y: -376px;
  }

  .date-location {
    font-size: 13px;
  }

  .stats {
    padding: 15px;
  }

  .dropit-trigger > a {
    font-size: 0.8rem;
  }

  .quick-links-menu .menu-items {
    padding-right: 2.125rem;
  }

  .quick-links-menu .menu-img img {
    max-width: 140px;
  }

  .quick-links-menu > .block {
    background-position: right -10px top -1867px;
  }

  /*stas*/
  .stats__number {
    font-size: 32px;
    line-height: 1.2;
  }

  .stats__icon {
    background-size: 70%;
    width: 65px;
  }

  .stats__text {
    font-size: 14px;
  }

  .stats__content {
    padding-left: 15px;
  }

  .stats__exhibitor .stats__icon {
    background-position: center -740px;
  }

  .stats__npe .stats__icon {
    background-position: center -828px;
  }

  .stats__new-products .stats__icon {
    background-position: center -910px;
  }

  #block-views-d32c3f8ecd3884b7a8ab524a057d773e {
    float: none;
    width: 100%;
  }

  .tradeshowfacts_wrapper table {
    width: 100%;
  }

 .view-see-new-products-by-category {
    width: 100%;
    padding-top: 20px;
    float: none;
  }

  #views-exposed-form-find-exhibitors-page .views-exposed-form,
  #views-exposed-form-view-registered-retailers-page .views-exposed-form,
  .page-favorites .views-exposed-form,
  form .views-exposed-form {
    float: none;
    width: 100%;
  }

  .promo {
    padding: 20px 0
  }

  .promo-image {
    vertical-align: top;
    display: block;
  }

  .promo-text {
    display: block;
    padding-left: 0;
    margin-top: 10px;
  }

  .page-favorites .views-exposed-form {
    background: #f9f9f9;
  }

  .page-favorites .views-exposed-form .views-exposed-widgets {
    padding: 10px;
  }

  .page-favorites #block-system-main, .page-shared-favorites #block-system-main {
    padding-left: 0px;
    margin-top: 10px;
  }

  .page-favorites .views-exposed-widget.views-widget-sort-by,
  .page-favorites .views-exposed-widget.views-widget-sort-order {
    /*width: 100%;
    display:  block;
    padding: 0;
    margin-bottom: 0;*/
  }

  .page-favorites .views-exposed-widget.views-widget-sort-by {
    margin-bottom: .5em;
  }

  div#block-favorites-share-share-my-favorites {
    width: 100%;
  }

  #fav-share-btn a {
    background: #475b96 none repeat scroll 0 0;
    color: #fff;
    text-decoration: none;
    margin: 10px;
    text-transform: uppercase;
  }

  #shared-info {
    display: block;
    width: 100%;
  }

  div#shared-by-user-msg {
    float: none;
    display: block;
    width: 100%;
    font-size: 14px;
  }

  div#add-fav-to-my-list {
    float: none;
    display: block;
    width: 100%;
  }

  div#add-fav-to-my-list a {
    width: 100%;
    display: block;
  }

  .page-favorites .shared-favorites h4 a,
  .page-shared-favorites .shared-favorites h4 a {
    padding: 10px 0 10px 10px;
    margin-bottom: 0;
  }

  #shared-header {
    position: relative;
    border-bottom: 1px solid #ddd;
    background: #eee;
    padding: 10px 0 10px 10px;
  }

  .shared-favorites ul li a {
    padding: 10px 0 10px 10px;
  }

  form .views-sort-options {
    float: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }

  #block-views-exp-favorites-page-1 .shared-favorites, #block-views-exp-favorites-page .shared-favorites {
    float: none;
    width: 100%;
    padding: 10px;
    margin-top: .5em;
  }

  .page-favorites .views-exposed-form, .page-shared-favorites .views-exposed-form {
    float: none;
    margin-bottom: .5em;
    width: 100%;
  }

  #block-favorites-share-share-my-favorites {
    position: inherit;
  }

  .ctools-auto-submit-full-form {
    width: 100%;
    float: none;
  }

  form .views-widget-sort-by {
    padding-left: 0 !important;
  }

  form .views-widget-sort-order {
    padding-right: 0 !important;
  }

  .page-view-exhibitors #block-system-main,
  .page-view-retailers #block-system-main,
  .page-see-new-products #block-system-main {
    padding-left: 0;
    clear: both;
  }

  .page-node .region-content:not(.node-type-webform .region-content) {
    padding: 20px;
  }

  .front #content {
    margin-bottom: 0;
  }

  .front #sidebar-first {
    margin-bottom: 5px;
  }

  .front #content .region-content .block .content a, .front #sidebar-first .region-sidebar-first .block .content a {
    font-size: 14px;
  }

 .front #content .region-content .block .content .menu-items a {
    padding: 5px 0;
  }

  #views-exposed-form-find-exhibitors-page .views-exposed-widget.views-submit-button {
    clear: left;
  }

  .view-find-exhibitors .view-header, .view-see-new-products-by-category .view-header, .view-view-registered-retailers .view-header {
    margin-top: 5px;
    padding-left: 0;
  }

  .page-view-exhibitors form .views-reset-button input[type="submit"] {
    display: none;
  }

  .filter-and-clear {
    display: table;
    width: 100%;
    margin-top: 1px;
  }

  .filter-options-handler,
  #clear-btn {
    width: 50%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  #clear-btn a {
    padding: 10px;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid #b64123;
    color: #b64123;
    border-radius: 3px;
  }

  .filter-options-handler {
    padding-right: 5px;
  }

  .filter-options-handler span {
    background: #e46724;
    display: block;
    color: #fff;
    text-align: center;
    padding: 10px 5px 10px 0px;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .page-view-exhibitors #block-system-main .content, .page-view-retailers #block-system-main .content, .page-favorites #block-system-main .content, .page-see-new-products #block-system-main .content {
    padding: 20px;
  }

  .filter-options-handler.open:before {
      background: url('../images/close-icon.png') no-repeat 10px center;
  }

  #block-content-customizations-download-exhibitors,
  #block-content-customizations-download-favorites {
    display: none;
  }

  .company_name {
  	width: auto;
  }

  .browse-links {
    display: block;
    width: auto;
    background: #ffffff;
    text-align: center;
    padding: 10px;
  }

  .browse-links .label {
    color: #666;
    font-size: 10px;
    line-height: 10px;
    padding: 5px 0;
    margin-top: -10px;
  }

  .browse-links ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .browse-links ul li {
    display: table-cell;
    width: 50%;
  }

  .browse-links li:first-child {
    padding-right: 5px;
  }

  .browse-links li:last-child {
    padding-left: 5px;
  }

  .browse-links ul li a {
    color: #fff;
    display: block;
    padding: 10px 5px;
    background: #475c96;
    border-radius: 3px;
    text-decoration: none;
  }

  .browse-links ul li a:hover {
    background: #159ab9;
  }

  .page-view-exhibitors form select[disabled="disabled"] {
    display: none;
  }

  form .views-exposed-form .views-exposed-widgets {
    padding: 10px;
    margin-top: 1px;
  }

  form .views-exposed-form .views-exposed-widgets.text-search {
    margin: 10px 0;
    padding: 0;
    background: transparent;
  }

  #webform-client-form-12135 label {
    font-size: 14px;
  }

  .dropit .dropit-submenu li a {
    white-space: normal;
  }

  .instructions-col__left {
    padding-right: 20px;
  }

  .instructions-col__right {
    width: 55%;
  }

  table.appointment-info,
  .exinfo,
  #user-appointment-request-form fieldset {
    padding: 20px;
  }

  body.page-explore-exhibitors {
    background: #333;
  }

  .page-explore-exhibitors .actionbar__wrapper {
    height: 50px;
  }

  .page-explore-exhibitors #page-title {
    position: inherit;
    font-size: 30px;
    text-align: center;
    margin: 30px 0;
  }

  .page-explore-exhibitors #logo {
    padding-top: 10px;
  }

  #speakers-seminars h2 {
    font-size: 20px;
  }

  .speaker-group ul li {
    width: 49%;
    margin-bottom: 15px;
  }

  #matchmaker-requests-container {
    top: 51px;
  }

  #matchmaker-requests div.title {
    line-height: 1.2;
  }

  #matchmaker-requests div.title a.close {
    height: 16px;
  }

  #notifications-menu li.notifications > a {
    background-position-y: -2152px;
  }

  #notifications-menu li.notifications.dropit-open > a {
    background-position-y: -2212px;
  }

  #notifications-menu li.notifications > a span.outer {
    top: 8px;
    right: 8px;
  }

  .previous-submissions {
    padding: 10px;
  }
}

@media only screen and (max-width: 710px) {
  #announce-new-product-form div.form-item,
  .container-inline-date {
    width: 100%;
  }

  .form-item-announced-product-npe-image input[type="file"]::before,
  .form-item-announced-product-image input[type="file"]::before {
    content: none;
  }

  .announce-new-product-form .form-item-announced-product-npe-consideration,
  .announce-new-product-form .form-item-announced-product-consideration {
    width: auto !important;
  }
}

@media only screen and (max-width: 750px) {
  /*#tertiary-menu li.matchmaker a, #tertiary-menu li.favorites a  {
    text-indent: -9999em;
    padding-left: 22px !important;
  }*/
}


@media only screen and (min-width: 640px) and (max-width: 750px) {
  /*#tertiary-menu li.matchmaker a, #tertiary-menu li.favorites a  {
    text-indent: -9999em;
    padding-left: 22px !important;
  }*/

  .quick-links-menu .menu-img img {
    max-width: 120px;
  }

  .quick-links-menu .menu-items {
    font-size: 15.5px;
  }

  .quick-links-menu > .block {
    background-position: right -10px top -1872px;
  }
}
