#header, #actionbar > .section, #breadcrumb {
  width: 1180px;
  margin: 0 auto;
}

#header {
  position: relative;
}

#header > .section {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.logged-in #header .col,
.not-front.not-logged-in #header .col,
#header-image {
  display: table-cell;
  vertical-align: middle;
}

#header-image {
  width: 245px;
  overflow: hidden;
}

#header-image img {
  display: block;
  margin: 0 0 0 10px;
}

#actionbar {
  margin-bottom: 10px;
  font-size: 14px;
  background: #fff;
}

.front #actionbar {
  margin-bottom: 5px;
}

#page {
  position: relative;
}

.front #page {
  padding-bottom: 0;
}

#page-background,
.front.not-logged-in #header-image
 {
  display: none;
}

.head-wrapper > .region-header {
  max-width: 960px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.front.not-logged-in,
.not-logged-in.page-user,
.not-logged-in.page-plma-user {
  background: rgb(220,226,242);
  background: linear-gradient(90deg, rgba(240,255,250,1) 0%, rgba(255,255,255,1) 50%, rgba(255,247,226,1) 100%);  height: 100%;
  position: relative;
}

.front.not-logged-in #page-wrapper,
.not-logged-in.page-user #page-wrapper,
.not-logged-in.page-plma-user #page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
  background: transparent;
}

.front.not-logged-in #header {
  margin: 0 auto;
  text-align: center;
}

.not-logged-in .login-screen,
.not-logged-in.page-plma-user .login-screen {
  max-width: 960px;
  margin: 0 auto;
  background: white;
  align-items: center;
  box-shadow: 0px 20px 50px 0px #ac998f;
}

.not-logged-in .login-screen__top {
  background: $primary-color;
}

.login-screen__top #logo {
  display: block;
  line-height: 0;
  a {
    width: 100%;
  }
}

.not-logged-in .header__site-name {
  font-size: 36px;
  text-transform: uppercase;
}

.header__site-slogan {
  font-family: 'avenir_next_lt_promedium', sans-serif;
  color: $yellow;
  font-size: 20px;
  margin: 0 0 10px 0;
  text-shadow: 0 2px 3px $primary-color-dark;
}

.header__show-date {
  color: $white;
  text-shadow: 0 2px 3px $primary-color-dark;
  text-transform: uppercase;
  position: relative;
  margin: 30px 0 0 0;
}

.not-logged-in .header__show-date::before {
  position: absolute;
  top: -15px;
  right: 0;
  bottom: auto;
  left: 0;
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  margin: auto;
  background: $primary-color-lighter;
}

.not-logged-in .login-screen__bottom {
  background-color: #ffffff;
  padding: 3rem;
  position: relative;
}

.front.not-logged-in .branding a {
  display: inline-block;
}

.front.not-logged-in #show-dates {
  float: none;
  height: auto;
  display: block;
  width: auto;
}

.front.not-logged-in .date-location {
  font-size: 22px;
  display: block;
  color: #fff102;
}

.front.not-logged-in #show-dates .meet-people {
  display: block;
  color: #fbe804;
  font-size: 38px;
}

.front.not-logged-in #site-name a {
  font-size: 48px;
  color: #ffffff;
  display: inline-block;
}

.front.not-logged-in #site-slogan {
  display: block;
  margin-top: 3px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
}

/*Message*/
.front.not-logged-in #block-block-32{
  background: #FEF5F1;
  padding: 25px;
  border: 1px solid #ED541D;
  color: #8c2e0b;
  width: 60%;
  margin: 0px auto 50px;
}

/*Access Code*/
#block-plma-user-access-user-login-access .form-item-access-code label {
  display: none;
}

#block-plma-user-access-user-login-access .show-floor-navigator #edit-access-code {
  float: none;
  display: block;
  width: 100%;
}

#block-plma-user-access-user-login-access .show-floor-navigator #edit-submit {
  float: none;
  display: block;
  width: 100%;
  margin: 10px 0;
}

#user-access-form > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
}

#user-access-form .form-item {
  width: 100%;
}

#user-access-form .form-item-remember-me,
#user-access-form .item-list-top {
  width: 50%;
}

#user-access-form .item-list-top {
  text-align: right;
}

#user-access-form .register-link-added {
  width: 100%;
  margin-bottom: 10px;
}

#user-access-form .register-link-added li:first-child {
  text-align: left;
}

#block-plma-user-access-user-login-access .show-floor-navigator hr {
  /*background: #5e6e79;*/
  background: transparent;
  height: 1px;
  border: none;
}

#block-plma-user-access-user-login-access .show-floor-navigator div.access-nocode {
  width: 100%;
}

#block-plma-user-access-user-login-access .show-floor-navigator #access-nocode {
  display: block;
  color: #fff;
  background: #007f9d;
  border-radius: 3px;
  line-height: 1;
  padding: 15px 20px;
  text-align: center;
  font-size: 14px;
  text-decoration: none !important;
}

#block-plma-user-access-user-login-access .show-floor-navigator #access-nocode:hover {
  background: #159ab9;
}

#block-plma-user-access-user-login-access .show-floor-navigator .item-list-bottom {
  display: none;
}

#plma-user-forgot-access-code .form-item-email label {
  display: none;
}

/*end home not logged-in style*/

.logged-in .meet-people {
  display: none;
}

.head-wrapper .region-header .block {
  float: right;
}

a#h-contact {
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: right;
  color: #fff;
  font-size: .8em;
  text-transform: uppercase;
  padding: 0 5px;
  margin: 0 10px;
}

.logged-in a#h-contact {
  display: none !important;
}

/*Sitename*/
#site-name a {
  text-decoration: none;
  font-size: 36px;
  color: #fff102;
  line-height: 1;
}

#site-slogan {
  display: none;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
}

#name-and-slogan {
  position: relative;
  width: 100%;
}

#name-and-slogan .section {
  padding-left: 10px;
}

.front.not-logged-in #name-and-slogan .section {
  padding-left: 0;
}

#page-title {
  margin: 0 0 30px 0;
  cursor: default;
  position: relative;
  z-index: 0;
}

.page-map #page-title {
  display: none;
}

.page-map #page-title {
  max-width: 1180px;
  padding: 0 20px;
  margin: 0 auto 40px;
}

/*Navigation - mathmaker, favories, and menu*/
#navigation {
  position: relative;
}

#navigation h2 {
  display: none;
}

#navigation ul {
  margin: 0;
}

#navigation ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

#navigation ul li ul {
  float: none;
}

#navigation ul li ul li {
  float: none;
}

#navigation #menu {
  display: none;
  padding: 0;
}

#navigation .links a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
}

/*matchmaker & favorite*/
#navigation ul#tertiary-menu {
  float: left;
}

#navigation #tertiary-menu li a {
  background: #fff;
  color: #375da2;
  display: block;
}

#navigation #tertiary-menu li a.active {
  background: #e2e7e7;
}

#navigation #tertiary-menu li a:hover {
  background: #f3f1ec;
}

.tertiary-menu-wrapper {
  flex-grow: 1;
}

#tertiary-menu li.announce_new_products a:before {
  background: url('../images/promote.png') no-repeat center transparent;
  display: inline-block;
  content: '';
}

#notifications-menu {
	margin: 0;
}

#notifications-menu li {
  padding: 0;
}

#notifications-menu li.notifications > a {
  background: url('../images/icon-sprites.png') no-repeat center -2147px;
  width: 55px;
  height: 66px;
  position: relative;
  margin-right: -1px;
  padding: 0 0 0 15px;
  display: inline-block;
}

#notifications-menu li.notifications.dropit-open > a {
  background-color: #fff !important;
  background-position-y: -2207px;
}

#notifications-menu li.notifications.dropit-open > a::after {
  content: '';
  width: 100%;
  position: absolute;
  height: 5px;
  display: block;
  background: white;
  left: 0;
  bottom: -3px;
  z-index: 100000;
}

#notifications-menu li.notifications > a span.outer {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 18px;
  min-width: 18px;
  display: table;
  background: $primary-color;
  border-radius: 50%;
  color: #fff;
  padding: 1px;
  font-size: 11px;
  line-height: 16px;
  opacity: .9;
}

#notifications-menu li.notifications > a span.inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 2px;
}

#notifications-menu li.notifications .hidden {
  display: none;
}

#notifications-menu li.notifications.dropit-open #matchmaker-requests-container {
	display: table !important;
	padding-left: 0 !important;
	left: 0;
	width: 100%;
}

.notifications #matchmaker-requests-list li a {
	height: auto !important;
	line-height: 21px !important;
	padding: 10px 10px 10px 30px !important;
}

#matchmaker-requests-container {
  top: 66px;
  padding: 0;
  background: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  pointer-events: none;
}

#matchmaker-requests {
  background: #f9f9f9;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.75);
  box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.75);
  pointer-events: auto;
  color: #555;
}

#matchmaker-requests .arrow {
  display: none;
  bottom: 100%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 12px;
  margin-left: -12px;
  pointer-events: none;
}

#matchmaker-requests div.title {
  background: #fff;
  font-family: 'avenir_next_lt_promedium';
  font-weight: normal;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #555;
}

#matchmaker-requests div.title a.close {
  float: right;
  height: 25px;
  width: 16px;
  text-indent: -9999em;
  padding: 0;
  margin: 0;
  background: url('../images/icon_menu_matchmaker_close.png') no-repeat 50% 50% !important;
  opacity: .8;
}

#matchmaker-requests div.title a.close:hover {
  opacity: 1;
}

#matchmaker-requests div.container {
  max-height: 227px;
  overflow-y: auto;
  padding: 0;
}

#matchmaker-requests-list {
  float: none !important;
  margin: 0;
}

#matchmaker-requests-list li {
  border-bottom: 1px solid #ddd;
}

#matchmaker-requests-list li:last-child {
  border-bottom: none;
}

#matchmaker-requests-list a {
  background-color: #f9f9f9 !important;
  color: #333 !important;
  text-transform: none !important;
  display: block !important;
  padding-left: 20px;
}

#matchmaker-requests-list li a:hover {
  background-color:  #fff !important;
}

#notifications-menu .dropit-open > a {
	background: #fff;
}

#matchmaker-requests-list .appointment-notification a {
	background: url(../images/icon_menu_matchmaker.png) no-repeat 10px 12px transparent !important;
	padding-left: 30px;
}

#matchmaker-requests-list .favorites-notification a {
	background: url(../images/icon_menu_favorites_brown.png) no-repeat 10px 12px transparent !important;
	padding-left: 30px;
}

/*Main Menu*/
#navigation #main-menu > li > a {
  text-align: center;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  padding: 0 15px;
}

#navigation #main-menu > li > a:hover {
  background: #f3f1ec;
}

#navigation #main-menu > li > a::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  background: url('../images/menu-icon.png') no-repeat center;
  vertical-align: middle;
}

#navigation #main-menu > li > a:hover::before,
#navigation #main-menu > li > a.selected::before {
  background: url('../images/menu-active.png') no-repeat center;
}

#navigation .dropit-open > a.selected {
  background-color: #fff !important;
  text-decoration: none !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.75);
  box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.75);
}

#navigation .links a:hover {
  background-color: #f3f1ec;
}

.dropit .dropit-submenu li a:hover,
.dropit .dropit-submenu li a.active {
  color: $secondary-color;
  font-family: 'avenir_next_lt_promedium', sans-serif;
}

#navigation ul.dropit-submenu li a {
  display: block;
  height: auto;
  padding: 10px;
  line-height: inherit;
}
/*end menu*/

.logged-in #page-wrapper {
  background: $body-bg;
}

#content {
  margin: 1em 0 40px 0;
}

#content > .section {
  position: relative;
}

.page-node .region-content:not(.node-type-webform .region-content) {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
}

.show_preview {
  margin-bottom: 20px;
}

.front.not-logged-in #content {
  margin-top: 140px;
  margin-bottom: 42px;
}

.front #sidebar-first {
  margin-bottom: 1em;
}

#footer {
  padding: 1em;
  text-align: center;
  font-size: 14px;
  z-index: 10;
}

.logged-in #footer p,
.not-front.not-logged-in #footer p:not(.not-logged-in.page-user #footer p):not(.not-logged-in.page-plma-user #footer p) {
  color: #999;
  line-height: 1;
}

#highlighted {
  margin-bottom: 10px;
}

.front #highlighted {
  margin-bottom: 5px;
}

#page-menu #notifications-menu {
	display: table-cell;
}

#page-menu #notifications-menu a {
	color: #fff;
}s

/*Front main content*/
.front #sidebar-first .region-sidebar-first {
  margin: 0 -7px;
}

/*front sidebar*/
.front #sidebar-first .region-sidebar-first .block .content {
  background: #fff;
  border: 7px solid #fff;
  border-radius: 7px;
  margin: 5px;
  overflow: hidden;
}

.front #sidebar-first .region-sidebar-first .block .content:hover {
  background: $primary-color-dark;
  border-color: #f6d970;
}

.front #sidebar-first .region-sidebar-first .block {
  width: 33.3%;
  float: left;
}

.front #sidebar-first .region-sidebar-first .block .menu-img {
  display: block;
}

.front #sidebar-first .region-sidebar-first .block img {
  width: 100%;
  vertical-align: top;
}
/*end sidebar*/
#announce-new-product-edit-form,
#announce-new-product-delete-form {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}

#npa-form-wrapper-header, #npe-form-wrapper-header {
  background: #f7f7f7;
  margin: 20px 0;
  border: 1px solid #edeadf;
}

.npe-form-content-wrapper,
.npa-form-content-wrapper {
	padding: 20px;
  background-color: #fff;
}

.npe-form-content-wrapper .npe-form-intro,
.npa-form-content-wrapper .npa-form-intro {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}

.npe-form-content-wrapper .npe-form-intro .info,
.npa-form-content-wrapper .npa-form-intro .info,
.npe-form-content-wrapper .npe-form-intro img,
.npa-form-content-wrapper .npa-form-intro img {
	display: table-cell;
	vertical-align: top;
}

.npe-form-content-wrapper .npe-form-intro .info,
.npa-form-content-wrapper .npa-form-intro .info {
  padding-right: 30px;
}

.npe-form-content-wrapper .npe-form-intro img,
.npa-form-content-wrapper .npa-form-intro img {
	width: 200px;
  max-width: none;
}

.npe-form-content-wrapper ul,
.npa-form-content-wrapper ul {
	line-height: 20px;
}

.npe-form-content-wrapper ul li,
.npa-form-content-wrapper ul li {
  margin-bottom: 10px;
}

.npe-form-content-wrapper .npe-text,
.npa-form-content-wrapper .npe-text {
	margin-bottom: 20px;
}


#npa-form-wrapper-header h3, #npe-form-wrapper-header h3 {
  background: #444;
  color: #fff;
  padding: 15px 20px;
  margin-bottom: 0;
}

form#announce-new-product-form {
  background: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 30px #ccc;
    box-shadow: 0px 10px 30px #ccc;
}

form#announce-new-product-form .title {
  padding-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
}

form #company-information > h3 {
	padding-bottom: 15px;
	margin-bottom: 0;
}

#npa-form, #npe-form {
  margin-top: 40px;
}

form.npe-disabled #npa-form {
  margin-top: 20px;
}

.previous-submissions-toggle.expand {
  background-position-y: -1375px;
  background-color: $primary-color;
}

.previous-submissions {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 5px 5px;
}

.product_announced_title {
  font-family: 'avenir_next_lt_promedium';
  font-size: 22px;
}

.saved .previous-submissions-toggle {
  border: 1px dashed #087c0c;
  background: url(../images/saved.png) center left 20px no-repeat #f0ffef;
  background-size: 20px;
  padding-left: 60px;
}

.flagged .previous-submissions-toggle {
  border: 1px dashed #000;
  background: url(../images/flagged-icon.png) center left 20px no-repeat #c0e0f3;
  background-size: 25px;
  padding-left: 60px;
}

/*announce new product*/
form#announce-new-product-form .npa-text p,
form#announce-new-product-form .npe-text p {
  font-weight: bold;
  color: red;
}

.announced_new_products_form_wrapper .header-text p.notice {
  margin-bottom: 0;
}

.announced_new_products_form_wrapper .header-text ul {
  margin-bottom: 0;
}

.form-item-announced-product-npe-innovative-features > label,
.form-item-announced-product-npe-innovation-benefits > label {
  font-size: 18px;
  color: #777 !important;
  text-transform: uppercase;
  margin-bottom: 10px;
}

#announce-new-product-form div.form-item label span {
  font-weight: normal;
}

/*radio*/
form .form-radios .form-type-radio {
  margin-top: 0;
}

form .form-radios .form-type-radio input[type="radio"] {
	display: none;
}


.form-type-radio label {
  font-family: 'avenir_next_lt_promedium', sans-serif !important;
  position: relative;
  vertical-align: top;
  padding-left: 1.875rem;
  margin: 0;
}

form .form-radios .form-type-radio label::before {
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #b2b2b2;
  content: '';
  display: inline-block;
  border-radius: 50%;
  margin: auto;
  background-color: #ffffff;
}

.form-type-radio input[type="radio"]:checked + label::before {
  border-color: $primary-color;
}

.form-type-radio input[type="radio"]:checked + label::after {
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: 5px;
  background: $primary-color;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto;
}

form .form-radios .form-type-radio input[type="radio"].error + label::before {
	border: 1px solid red;
}

/*checkbox*/
.form-type-checkbox input[type="checkbox"] {
  display: none;
}

#announce-new-product-form #edit-announced-product-npe-presented-in-previous-show .form-type-radio,
#announce-new-product-form #edit-announced-product-retailer-info-in-prod-img .form-type-radio,
#edit-announced-product-npe-additional-description-yes-no .form-type-radio,
#edit-announced-product-npa-additional-description-yes-no .form-type-radio,
#announce-new-product-form #edit-announced-product-npe-retail-brand-availability-yes-no .form-type-radio {
  display: inline-block;
  width: auto;
  padding-right: 20px;
}

.announced_new_products_form_wrapper .header-text {
  margin: 0 auto 20px;
}

.announced_new_products_form_wrapper .header-text p.notice {
  margin-bottom: 0;
}

.announced_new_products_form_wrapper .header-text ul {
  margin-bottom: 0;
}

.announce-new-product-form .form-item-announced-product-npe-consideration,
.announce-new-product-form .form-item-announced-product-consideration {
  margin: 0 -20px -20px -20px;
  padding: 20px;
  background: #edf0f3;
}

.form-item .description {
  margin-top: 5px;
}

form .form-managed-file {
  padding: 15px;
  background-color: #f1f4f7;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
}

form .form-managed-file input[type="file"] {
  width: 100%;
}

#previous-submissions .product-edit,
#previous-submissions .product-delete {
  display: inline-block;
  background: url('../images/edit-delete.png') no-repeat left 2px;
  padding: 0 10px 0 20px;
  margin: 0 10px 10px 0;
  border-right: 1px solid #ccc;
}

#previous-submissions .product-delete {
  background-position: left -51px;
  border-right: none;
}

.form-item-announced-product-description .description,
.form-item-announced-product-npe-description .description {
  text-align: right;
  padding: 5px 0;
}

.form-item-announced-product-npe-innovative-features .form-item .form-item-description,
.form-item-announced-product-npe-innovation-benefits  .form-item .form-item-description {
  background: #fff;
}

.form-item-announced-product-npe-innovative-features .form-item .form-item-description .description,
.form-item-announced-product-npe-innovation-benefits  .form-item .form-item-description  .description {
  text-align: right;
  padding: 5px 0;
}

#edit-announced-product-qualifiers {
  width: 100%;
  float: none;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #e5e5e5;
}

#edit-announced-product-qualifiers .form-item {
  padding: 0;
}

.form-item-announced-product-user-name,
.form-item-announced-product-user-email {
  display: inline-block;
  float: left;
  margin-right: 2%;
}

.form-item-announced-product-user-title,
.form-item-announced-product-user-phone {
  display: inline-block;
}

#announce-new-product-form div.form-item select::-ms-expand {
  display: none;
}

#announce-new-product-form div.form-item textarea {
  width: 100%;
  padding: 10px;
  background: #f0f4f7;
  border: 1px solid #dedede;
  height: 100px;
}

/*checkbox*/
.form-type-checkbox {
  position: relative;
}

.form-type-checkbox input[type="checkbox"] {
  display: none;
}

.form-type-checkbox label {
  position: relative;
  padding-left: 1.875rem;
  display: inline-block;
  line-height: 24px;
}

.form-type-checkbox label::before {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #cdcdcd;
  content: '';
  display: inline-block;
  border-radius: 3px;
  margin: auto;
  background-color: #ffffff;
}

.form-type-checkbox input[type="checkbox"]:checked + label::before {
  background: url('../images/icon-sprites.png') no-repeat;
  background-position: top -1598px center;
  background-color: $primary-color-dark;
  border-color: $primary-color-dark;
}

.form-type-checkbox input[type="checkbox"] + label {
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0 !important;
  height: auto;
}

.announce-new-product-form .form-item-announced-product-npe-consideration label::before,
.announce-new-product-form .form-item-announced-product-consideration label::before {
  bottom: 0;
  left: 20px;
}

.form-item .error {
  background: #fef5f1 !important;
  border-color: #f9c9bf !important;
}

#announce-new-product-form div.form-item input[type="checkbox"] {
  display: none;
}

.form-item-announced-product-npe-agree-rules input[type="checkbox"]:checked + label {
  background-position: 0 14px !important;
}

.form-item-announced-product-npe-innovative-features input[type="checkbox"]:checked + label,
.form-item-announced-product-consideration input[type="checkbox"]:checked + label,
.form-item-announced-product-npe-consideration input[type="checkbox"]:checked + label,
.form-item-announced-product-npe-innovation-benefits input[type="checkbox"]:checked + label,
.announce-new-product-form .form-item-announced-product-npe-innovative-features .form-item input[type="checkbox"]:checked + label,
.announce-new-product-form .form-item-announced-product-npe-new-as-private-label .form-item input[type="checkbox"]:checked + label {
  background-color: $primary-color !important;
  color: #fff;
}

.form-item-announced-product-npe-image .form-managed-file,
.form-item-announced-product-image .form-managed-file,
.form-item-announced-product-retailer-authorized-document .form-managed-file {
  position: relative;
}

#edit-announced-product-image-ajax-wrapper .description,
#edit-announced-product-retailer-authorized-document-ajax-wrapper .description {
  padding: 5px 0;
  font-style: italic;
}

.form-item-announced-product-npe-image input[type="submit"],
.form-item-announced-product-image input[type="submit"],
.form-item-announced-product-retailer-authorized-document input[type="submit"] {
  background: $primary-color;
  border-radius: 0 3px 3px 0;
  border: none;
  padding: 0 20px;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: 0;
  cursor: pointer;
}

.form-item-announced-product-retailer-authorized-document {
  padding: 20px;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.form-item-announced-product-retailer-authorized-document label {
  font-family: 'avenir_next_lt_promedium';
}

.form-item-announced-product-retailer-authorized-document label a {
  font-family: 'avenir_next_lt_promedium';
}

#announce-new-product-form .form-item-announced-product-consideration label,
#announce-new-product-form .form-item-announced-product-npe-consideration label {
  padding: 18px 18px 18px 55px;
  background-position: 15px -162px;
  margin: 0;
  width: 100%;
  display: block;
  border-radius: 5px;
  box-shadow: 0 4px 3px #ccc;
  background-color: #ffffff;
}

#announce-new-product-form .form-item-announced-product-consideration input[type="checkbox"]:checked + label,
#announce-new-product-form .form-item-announced-product-npe-consideration input[type="checkbox"]:checked + label {
  background-position: 15px 15px;
}

#announce-new-product-form .tooltip-icon {
  display: inline-block;
  text-indent: -99999em;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  background: url("../images/help-icon.png") no-repeat center;
}

.form-item-announced-product-npe-image input[type="submit"]:hover,
.form-item-announced-product-image input[type="submit"]:hover,
.form-item-announced-product-retailer-authorized-document input[type="submit"]:hover {
  background-color: $primary-color-dark;
}

#announce-new-product-form div.form-item.form-item-announced-product-retailer-authorized-document {
  width: 100%;
}

.form-item-announced-product-npe-image,
.form-item-announced-product-title,
.form-item-announced-product-category,
.form-item-announced-product-retailer-authorized-document .form-managed-file,
.form-item-announced-product-image .form-managed-file {
  width: 50%;
}

.container-inline-date {
  width: 49%;
  display: inline-block;
  margin-right: 2%;
  vertical-align: top;
  margin-bottom: 20px;
}

.form-item-announced-product-npe-country-first-launch {
  display: inline-block;
  padding: 0 !important;
}

.container-inline-date .date-padding {
  float: none;
}

.container-inline-date .form-item {
  padding: 0 !important;
  width: 100% !important;
}

.container-inline-date .date-padding label,
.container-inline-date .date-padding .description  {
  display: none !important;
}

.form-item-announced-product-npe-innovative-features div.form-checkboxes,
.form-item-announced-product-npe-innovation-benefits div.form-checkboxes,
.form-item-announced-product-npe-new-as-private-label .form-checkboxes {
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.form-item-announced-product-npe-new-as-private-label div.form-checkboxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-item-announced-product-npe-new-as-private-label div.form-checkboxes .form-item {
  margin: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 5px;
  width: 49.7%;
}

.form-item-announced-product-npe-new-as-private-label div.form-checkboxes .form-item:last-child {
  margin-bottom: 0;
}

.form-item-announced-product-npe-innovative-features div.form-checkboxes .form-item,
.form-item-announced-product-npe-innovation-benefits div.form-checkboxes .form-item {
  background: #f5f5f5;
  margin: 5px 0;
}

.form-item-announced-product-npe-innovative-features div.form-checkboxes .form-item label,
.form-item-announced-product-npe-innovation-benefits div.form-checkboxes .form-item label {
  margin-bottom: 0 !important;
}

.announce-new-product-form .form-item-announced-product-npe-innovative-features .form-item label,
.announce-new-product-form .form-item-announced-product-npe-innovation-benefits .form-item label,
.announce-new-product-form .form-item-announced-product-npe-new-as-private-label .form-item label {
  padding: 10px 10px 10px 50px;
  display: block;
  background-color: #f5f5f5;
}

.announce-new-product-form .form-item-announced-product-npe-innovative-features .form-item label::before,
.announce-new-product-form .form-item-announced-product-npe-innovation-benefits .form-item label::before,
.announce-new-product-form .form-item-announced-product-npe-new-as-private-label .form-item label::before {
  bottom: 0;
  left: 15px;
}

.saved .previous-submissions.expand .complete-and-submit a,
.flagged .previous-submissions.expand .complete-and-submit a {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 3px;
  margin-top: 20px;
  text-decoration: none;
  font-size: 15px;
  font-family: 'avenir_next_lt_promedium';
  border: 1px solid $secondary-color;
  text-transform: uppercase;
  background-color: $secondary-color;
  color: #fff;
}

.saved .previous-submissions.expand .complete-and-submit a:hover,
.flagged .previous-submissions.expand .complete-and-submit a:hover {
  background-color: $primary-color-dark;
}

.saved .previous-submissions-toggle.expand {
  background: url('../images/saved-white.png') center left 20px no-repeat $primary-color;
  background-size: 20px;
}

.flagged .previous-submissions-toggle.expand {
  background: url('../images/flagged-icon-white.png') center left 20px no-repeat $primary-color;
  background-size: 20px;
}

.form-item-announced-product-user-name,
.form-item-announced-product-user-title,
.form-item-announced-product-user-email,
.form-item-announced-product-user-phone {
  width: 49%;
}

.form-item-announced-product-company-text {
  margin-top: 20px;
}

.disclaimer {
  padding: 15px;
  padding-left: 15px;
  background-image: svg-load('../images/box.svg', fill='#{$tertiary-color}');
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-color: #f9f9f9;
    background-size: auto;
  -webkit-background-size: 25px 25px;
  background-size: 25px;
  border-left: 3px solid #ee6e17;
  padding-left: 45px;
  margin-top: 10px;
  font-size: 0.9375rem;
  line-height: 1.3125rem;
  font-family: 'avenir_next_lt_probold';
  font-style: normal;
  -webkit-box-shadow: 0 3px 3px #e5e5e5;
  box-shadow: 0 3px 3px #e5e5e5;
  border-radius: 0 3px 3px 0;
}

#edit-submit-disclaimer {
  font-family: 'avenir_next_lt_proitalic';
  border: 1px dashed #cdcdcd;
  padding: 20px;
  margin-bottom: 0;
  border-radius: 5px;
}

#edit-submit-disclaimer a {
  font-family: 'avenir_next_lt_promedium';
}

div.submit_announce_new_product {
  text-align: left;
}

#announce-new-product-form div#edit-action.form-item {
  width: 100%;
}

.confirm-message .submit-disclaimer {
  display: none;
}

#edit-submit-disclaimer {
  width: 100% !important;
}

.announced_new_products_form_wrapper div.messages p {
  color: #000;
}

.messages.success {
  background: #E8F9E5;
  border-color: #ADDAA7;
}

.announced_new_products_form_wrapper #block-system-main {
  margin-top: 1em;
}

#announce-new-product-delete-form h2 {
  margin-top: 0;
}

.views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
  margin-bottom: 10px;
}

#edit-field-state-value-wrapper {
  margin-bottom: 20px;
}

.views-exposed-form .views-exposed-widget:last-child {
	margin-bottom: 0;
}

.has-sidebar .shared-favorites {
  margin-top: 0;
}

#shared-with-wrapper {
  font-size: 14px;
  margin-top: 10px;
}

#shared-with-wrapper .shared-with{
  font-weight: bold;
}

.block.block-favorites-share {
  display: inline-block;
  float: right;
  font-size: 12px;
  margin-left: 10px;
  text-align: center;
  width: 165px;
}

#-favorites-share-form .form-item {
  margin-top: 1em;
}

.block.block-favorites-share .content a {
  background: #fff none repeat scroll 0 0;
  display: block;
  padding: 5px 20px;
  border-radius: 3px;
}

.block.block-favorites-share .content a:hover {
  background-color: $primary-color-dark;
  color: #fff;
  text-decoration: none;
}

.block.block-favorites-share .content a:hover::before {
  background-position: top -2010px center !important;
}

#shared-info {
  display: inline-block;
  width: 100%;
}

div#shared-by-user-msg {
  float: left;
}

div#shared-by-user-msg span.info {
  font-weight: normal;
}

div#shared-by-user-msg span.sharer {
  font-weight: bold;
}

div#add-fav-to-my-list {
  float: right;
}

div#add-fav-to-my-list a {
  font-size: 12px;
  text-align: center;
  padding: 5px 20px;
  border: 1px solid $secondary-color;
  color: $secondary-color;
  border-radius: 3px;
}

div#add-fav-to-my-list a:hover {
  background-color: $secondary-color-dark;
  color: #fff;
  text-decoration: none;
}

.shared-all-fav {
  color: #234600;
}

#fav-share-btn a:before {
  width: 15px;
  height: 16px;
  margin: 0 5px 8px 0;
  display: inline-block;
  content: '';
  vertical-align: middle;
  background: url(../images/icon-sprites.png) no-repeat top -1950px center;
}

.page-shared-favorites #views-exposed-form-favorites-page-1 .views-exposed-form {
  display: none;
}

.page-favorites .views-exposed-form,
.page-shared-favorites .views-exposed-form {
  margin-bottom: 20px;
  width: 270px;
  float: left;
  clear: left;
  background: #f9f9f9;
}

.page-favorites .shared-favorites h4, .page-shared-favorites .shared-favorites h4 {
  margin-bottom: 0;
}

.page-favorites .shared-favorites h4 a,
.page-shared-favorites .shared-favorites h4 a {
  padding: 18px 0 18px 18px;
  display: block;
  text-decoration: none;
}

.page-favorites .shared-favorites #my-favorites, .page-shared-favorites .shared-favorites #my-favorites {
  border-bottom: 1px solid #ddd;
}

#my-favorites a::before {
  background: transparent url("../images/icon_menu_favorites.png") no-repeat scroll center center;
  content: "";
  padding-left: 20px;
  margin-right: 5px;
}

#edit-shared-favorites h4 {
  font-weight: normal;
  font-size: 16px;
}

#shared-header {
  position: relative;
  border-bottom: 1px solid #ddd;
  background: #eee;
  cursor: pointer;
  padding: 18px 0 18px 18px;
}

#shared-to-me {
  margin: 0;
}

.shared-favorites ul li {
  list-style: none;
  border-bottom: 1px solid #ddd;
}

.shared-favorites ul li a {
  font-size: 16px;
  padding: 15px 0 15px 10px;
  display: block;
  background: #f1f0ee;
  color: #666;
  text-decoration: none;
}

.shared-favorites ul li a:hover {
  background: #fff;
}

.shared-favorites ul li a.active {
  background: #fff;
}

.shared-favorites ul li a::before {
  background: transparent url("../images/icon_menu_favorites_brown.png") no-repeat scroll center center;
  content: "";
  padding-left: 32px;
}

.shared-favorites ul li a span {
  font-weight: normal;
}

#shared-header::before {
  background: transparent url("../images/share-icon-dark.png") no-repeat scroll center center;
  content: "";
  padding-left: 20px;
  margin-right: 5px;
}

#shared-header.expand::before {
  background: transparent url("../images/share-icon-white.png") no-repeat scroll center center;
  content: "";
  padding-left: 20px;
}

#views-exposed-form-favorites-page-1 #shared-header::before {
  background: transparent url("../images/share-icon-white.png") no-repeat scroll center center;
  content: "";
  padding-left: 20px;
}

#views-exposed-form-favorites-page-1 #shared-header.expand::before {
  background: transparent url("../images/share-icon-dark.png") no-repeat scroll center center;
  content: "";
  padding-left: 20px;
}

#views-exposed-form-favorites-page #shared-header,
#views-exposed-form-favorites-page-1 #shared-header.expand {
  background: url('../images/plus_fav.png') no-repeat right center;
}

#views-exposed-form-favorites-page #shared-header.expand,
#views-exposed-form-favorites-page-1 #shared-header {
  background: url('../images/minus_fav.png') no-repeat right center $primary-color;
  color: #fff;
}

#views-exposed-form-favorites-page-1 #shared-header.expand {
  color: #333;
}

.shared-favorites .favorites-count {
  float: right;
  margin: -2px 10px 0 0;
  border-radius: 50%;
  background: $primary-color;
  color: #fff;
  text-align: center;
  height: 25px;
  width: 25px;
  font-size: 14px;
}

.page-favorites .views-exposed-form .views-exposed-widgets, .page-shared-favorites .views-exposed-form .views-exposed-widgets {
  padding: 20px;
  margin: 0;
}

.page-favorites .views-exposed-form #edit-clear,
.page-shared-favorites .views-exposed-form #edit-clear {
  margin-top: 0;
  cursor: pointer;
  width: 100%;
  background: $primary-color;
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.page-favorites .views-exposed-form #edit-clear:hover,
.page-shared-favorites .views-exposed-form #edit-clear:hover {
  background: $primary-color-dark;
}

.page-favorites #block-system-main, .page-shared-favorites #block-system-main,
.page-favorites.hide-download-buttons.has-sidebar #block-system-main, .page-shared-favorites.hide-download-buttons.has-sidebar #block-system-main {
  padding-left: 280px;
}

.page-favorites.hide-download-buttons #block-system-main, .page-shared-favorites.hide-download-buttons #block-system-main {
  padding-left: 0;
}

.page-favorites .views-exposed-widget label,
.page-shared-favorites .views-exposed-widget label {
  font-weight: normal;
  margin-bottom: .25em;
}

.page-favorites .views-exposed-widget.views-widget-sort-by select,
.page-favorites .views-exposed-widget.views-widget-sort-order select {
  border: 1px solid #d9dbdb;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 13px;
}

.form-item-has-advertisement input[type="checkbox"],
.form-item-field-product-rejected-value input[type="checkbox"],
.form-item-has-npe input[type="checkbox"] {
  width: 15px;
}

.form-item-has-advertisement label,
.form-item-field-product-rejected-value label,
.form-item-has-npe label {
  font-size: 14px;
}

.page-view-exhibitors form .form-type-checkbox {
	display: table;
	width: 100%;
	padding: 0 0 10px 0;
	border-spacing: 0;
	box-sizing: border-box;
}

.page-view-exhibitors form .form-type-checkbox label {
	font-weight: normal;
	width: 100%;
}

.view-see-new-products-view .view-header {
  margin-bottom: 1.5em;
}

.view-see-new-products-view ul.list-category {
  padding: 0 10px;
}

.view-see-new-products-view ul.list-category li {
  padding-bottom: 4px;
}

.view-see-new-products-view ul.list-category .field-content {
  color: #444;
}

.view-see-new-products-view ul.list-category a {
  display: inline-block;
  padding: 4px 6px;
  color: #333;
}

.view-see-new-products-by-category .views-row {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.view-see-new-products-by-category .views-field-field-product-image,
.view-see-new-products-by-category .new-products-content {
  display: table-cell;
  vertical-align: middle;
}

.view-see-new-products-by-category .new-products-content {
  width: 100%;
  padding-left: 20px;
}

.view-see-new-products-by-category .views-field-field-product-image {
  padding-right: 10px;
  width: 125px;
  text-align: center;
}

.view-see-new-products-by-category .views-field-field-product-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.view-see-new-products-by-category .view-content {
  font-size: 11.5pt;
}

.view-see-new-products-by-category .view-filters {
  margin-bottom: 1.5em;
}

.view-see-new-products-by-category .view-header {
  margin-bottom: 1.5em;
}

.view-see-new-products-by-category .views-field-field-product-image {
  padding-right: 10px;
  width: 125px;
  text-align: center;
}

.view-see-new-products-by-category .views-label-title {
  font-family: 'avenir_next_lt_promedium';
}

.view-see-new-products-by-category .views-field-field-booth::before {
  content: '|';
  display: inline-block;
  margin: 0 10px;
  color: #b5b5b5;
}

.view-see-new-products-by-category .views-field-title span,
.view-see-new-products-by-category .views-field-title h4 {
  display: inline-block;
}

.view-see-new-products-by-category .views-field-title h4 {
  font-family: 'avenir_next_lt_proregular';
  margin: 0;
}

.view-see-new-products-by-category .view-content .views-field-name-1 {
  font-size: 14px;
}

.view-npe-online .expo-category,
.view-see-new-products-by-category .views-field-name-1 .field-content {
  background-color: #f0f0f0;
  border-radius: 30px;
  padding: 0 10px;
  font-family: 'avenir_next_lt_promedium';
  font-weight: normal;
  margin: 5px 0;
  display: inline-block;
}

.view-see-new-products-by-category .view-content .views-field-name-1 .product {
  color: #333;
  text-transform: uppercase;
}

#views-exposed-form-see-new-products-by-category-page #edit-field-computed-title-value {
  width: 100%;
  border: 1px solid #c1b99d;
  padding: 10px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 0;
}

.form .views-exposed-widgets,
form .views-exposed-widgets {
  border-collapse: separate;
  border-spacing: 10px 0;
  margin: 0;
  background: none repeat scroll 0 0 #f9f9f9;
  padding: 20px;
}

form .views-widget-sort-by {
  padding-left: 0 !important;
}

form .views-widget-sort-order {
  padding-right: 0 !important;
}

#views-exposed-form-see-new-products-by-category-page label,
#views-exposed-form-view-registered-retailers-page label {
  margin-bottom: 0.25em;
  font-family: 'avenir_next_lt_promedium';
  font-weight: normal;

}

form select:disabled {
  background: #eee;
  color: #999;
}

#user-appointment-request-form .form-select {
  width: 100% !important;
}

table.appointment-info {
  margin-bottom: 1em;
  background-color: #fff;
  border-radius: 5px;
  display: block;
  padding: 30px;
}

table.appointment-info tbody tr {
  border-bottom: 1px solid #e5e5e5;
}

table.appointment-info tbody tr:last-child {
  border-bottom: none;
}

table.appointment-info tbody tr th {
  padding: 10px;
  background-color: #f0f0f0;
}

table.appointment-info td {
  padding: 5px 10px;
  background-color: #f9f9f9;
}

#views-exposed-form-see-new-products-by-category-page #edit-field-computed-search-data-value-wrapper .views-widget {
  position: relative;
  margin-top: .5em;
}

.form .views-exposed-form .views-exposed-widgets,
form .views-exposed-form .views-exposed-widgets {
  margin-bottom: 0.5em;
}

.view-see-new-products-by-category .view-content .views-row {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: .75em;
  margin-bottom: .75em;
}

.view-see-new-products-by-category .view-content h3 {
  margin: 0 0 .25em 0;
  font-family: 'avenir_next_lt_promedium';
}

.view-see-new-products-by-category .view-content .views-field-name-1 {
  text-transform: uppercase;
  margin-top: 10px;
}

.view-see-new-products-by-category .view-content .views-field-name-1 .product {
  color: #333;
  text-transform: uppercase
;}

div.product_title, .company_name {
  text-transform: uppercase;
}

#block-content-customizations-download-favorites,
#block-content-customizations-download-exhibitors,
#block-favorites-share-share-my-favorites {
  position: absolute;
  top: -5px;
  right: 0;
}

#block-favorites-share-share-my-favorites {
  right: 200px;
}

.page-view-exhibitors #block-content-customizations-download-favorites {
  right: 207px;
}

#block-content-customizations-download-exhibitors,
#block-content-customizations-download-favorites {
  display: inline-block;
  font-size: 12px;
  text-align: center;
}

#block-content-customizations-download-exhibitors .content a,
#block-content-customizations-download-favorites .content a {
  display: block;
  padding: 5px 20px;
  background: #fff;
  border-radius: 5px;
}

#block-content-customizations-download-exhibitors .content a:before,
#block-content-customizations-download-favorites .content a:before {
  width: 15px;
  height: 15px;
  margin: 0 5px 8px 0;
  display: inline-block;
  content: '';
  vertical-align: middle;
  background: url('../images/icon-sprites.png') no-repeat top -1720px center;
}

#block-content-customizations-download-exhibitors .content a:hover,
#block-content-customizations-download-favorites .content a:hover {
  color: #fff;
  background: $primary-color-dark;
  text-decoration: none;
}

#block-content-customizations-download-exhibitors .content a:hover::before,
#block-content-customizations-download-favorites .content a:hover::before {
  background-position-y: -1780px;
}

#views-exposed-form-find-exhibitors-page {
  position: relative;
}

form .views-sort-options {
  background: #fff;
  display: table;
  clear: left;
  float: left;
  padding: 20px;
  width: 270px;
  border-radius: 5px;
  box-sizing: border-box;
}

.ctools-auto-submit-full-form {
  width: 270px;
  float: left;
}

.page-explore-exhibitors .ctools-auto-submit-full-form {
  width: 100%;
  float: none;
}

form .views-sort-options .form-item {
  margin-bottom: 10px;
}

.page-favorites form .views-sort-options,
.page-shared-favorites form .views-sort-options {
  float: none;
}

form .shared-favorites {
  width: 270px;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 5px;
}

form .views-sort-options .views-exposed-widget {
  display: block;
}

#views-exposed-form-find-exhibitors-page .views-sort-options .views-exposed-widget label {
  display: block;
  font-weight: normal;
  padding: 0px;
  margin-bottom: .25em;
}

.form .views-exposed-form,
form .views-exposed-form {
  clear: left;
  float: left;
  width: 270px;
}

form .views-exposed-form .views-exposed-widgets {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}

#views-exposed-form-find-exhibitors-page #edit-field-computed-search-data-value-wrapper .views-widget {
  position: relative;
  margin-top: .5em;
}

#views-exposed-form-find-exhibitors-page #edit-field-computed-search-data-value-wrapper label {
  font-size: 14px;
}

.form .views-exposed-widgets .views-exposed-widget input[type="text"].form-text,
form .views-exposed-widgets .views-exposed-widget input[type="text"].form-text {
  margin: 0;
  font-size: 15px;
}

.form .views-exposed-widget .views-widget,
form .views-exposed-widget .views-widget {
  position: relative;
}

.form .views-exposed-widgets.text-search input[type="submit"],
form .views-exposed-widgets.text-search input[type="submit"] {
  background: $primary-color url('../images/search-icon.png') no-repeat center;
  position: absolute;
  display: inline;
  margin: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  right: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 0 3px 3px 0;
  border: none;
  width: 40px;
  font-size: 0;
  background-size: 15px;
}

.form .views-exposed-widgets.text-search input[type="submit"]:hover,
form .views-exposed-widgets.text-search input[type="submit"]:hover {
  background-color: $primary-color-dark;
}

#views-exposed-form-find-exhibitors-page #edit-submit-find-exhibitors-search:hover {
  background-color: $primary-color-dark;
}

form#webform-client-form-30777 input[type="text"],
form#webform-client-form-30777 input[type="email"] {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}

form#webform-client-form-30777 .form-textarea-wrapper textarea {
  padding: 10px;
  margin-top: 5px;
}

.form-item-notification-email {
  padding: 20px;
  background: #fff;
}

form#webform-client-form-30777 .form-submit[type="submit"],
#edit-submit[type="submit"],
div.member_appointment_form_div a,
form#user-appointment-calender-matchmaker-settings-form .form-submit {
  background: $primary-color;
  padding: 15px 20px;
  margin-top: 15px;
  text-transform: uppercase;
  color: #000;
  font-size: 15px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  margin-right: 10px;
  width: 100%;
}

form#user-appointment-calender-matchmaker-settings-form .form-submit {
  display: block;
  margin: 20px auto 0;
}

.confirm-message .submit_announce_new_product input[type="submit"] {
  margin-right: 0;
}

form#webform-client-form-30777 .form-submit[type="submit"]:hover,
#edit-submit[type="submit"]:hover,
div.member_appointment_form_div a:hover {
  background: $primary-color-dark;
  color: #fff;
}

#announce-new-product-form #edit-action {
  display: flex;
  flex-wrap: wrap;
}

#announce-new-product-form #edit-action .submit-disclaimer {
  width: 100%;
  flex: 0 0 auto;
}

#announce-new-product-form #edit-action input[type="submit"],
#announce-new-product-form #edit-action .submit_announce_new_product {
  flex: 0 0 auto;
}

#edit-save {
  font-family: 'avenir_next_lt_promedium';
  background-color: #fff;
  border: 1px solid #fdbc44;
  padding: 15px 20px;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 10px;
}

form .views-reset-button {
	clear: both;
}

form .views-reset-button input[type="submit"] {
	background: #fff;
	border: 1px solid $primary-color;
	padding: 5px 15px;
  color: $primary-color;
	border-radius: 3px;
  cursor: pointer;
	width: 100%;
}

.page-view-exhibitors .views-reset-button input {
	margin-top: 0 !important;
}

form .views-reset-button input[type="submit"]:hover {
	background: $primary-color;
	color: #fff;
}

.page-view-exhibitors #block-system-main,
.page-view-retailers #block-system-main,
.page-see-new-products #block-system-main {
  padding-left: 280px;
}

.page-view-exhibitors #block-system-main .content,
.page-view-retailers #block-system-main .content,
.page-favorites #block-system-main .content,
.page-see-new-products #block-system-main .content,
.page-shared-favorites #block-system-main .content {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
}

.page-view-exhibitors-by-category #block-system-main {
	padding-left: 0;
}

.view-find-exhibitors .favorites {
  font-size: 0;
  text-align: center;
}

.view-find-exhibitors .favorites a {
  color: #333;
  display: inline-block;
  padding: 0 10px 5px 0;
}

.view-find-exhibitors .favorites a.flag:before {
  display: block;
  content: '';
  width: 39px;
  height: 40px;
  background-image: url('../images/icon_favorites.png');
  background-repeat: no-repeat;
  background-position: 0 0;
}

.view-find-exhibitors .favorites a.flag.unflag-action:before {
  background-position: -39px 0;
}

.flag-wrapper .flag-throbber, .flag-wrapper .flag-message {
  display: none !important;
}

.view-find-exhibitors .views-row, .view-view-registered-retailers .views-row {
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  overflow: hidden;
}

.view-find-exhibitors .views-row {
  display: table;
  width: 100%;
}

.view-find-exhibitors .views-row .col1 {
  display: table-cell;
  vertical-align: top;
}

.view-find-exhibitors .views-row .col2 {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.view-find-exhibitors h3.field-content, .view-view-registered-retailers h3.field-content {
  margin: 0;
}

.view-find-exhibitors .views-field-field-country, .view-find-exhibitors span.views-field-php {
  font-size: 15px;
  display: table-cell;
}

.view-find-exhibitors .views-field-field-country::after {
	content: '|';
	padding: 0 10px 0 8px;
	color: #777;
}

.view-view-registered-retailers .views-field-field-company, .view-view-registered-retailers .views-field-field-php-1 {
  display: inline-block;
}

.view-find-exhibitors .views-field-field-booth {
  font-size: 15px;
  display: table-cell;
}

.view-find-exhibitors .views-field-field-product {
  display: block;
  font-size: 15px;
  line-height: 24px;
}

.view-find-exhibitors .views-field-field-product .views-label {
  font-weight: bold;
}

.view-find-exhibitors .views-field-php .notifications {
  margin-top: .25em;
}

.view-find-exhibitors .views-field-php .notification {
  display: inline-block;
  background: #fff;
  padding: 4px 15px 4px 5px;
  border-radius: 10px;
  font-size: 11.5px;
  text-transform: uppercase;
  margin: 0 6px 0 0;
}

.view-find-exhibitors .views-field-php .notification span {
  display: table-cell;
  vertical-align: middle;
}

.view-find-exhibitors .views-field-php .notification img {
  display: block;
  margin-right: 5px;
}

.view-find-exhibitors .views-field-custom-field .notifications {
  margin-top: .25em;
}

.view-find-exhibitors .views-field-custom-field .notification {
  display: inline-block;
  background: #fff;
  padding: 4px 15px 4px 5px;
  border-radius: 10px;
  font-size: 11.5px;
  text-transform: uppercase;
  margin: 0 6px 0 0;
}

.view-find-exhibitors .views-field-custom-field .notification span {
  display: table-cell;
  vertical-align: middle;
}

.view-find-exhibitors .views-field-custom-field .notification img {
  display: block;
  margin-right: 5px;
}

.view-find-exhibitors .schedule-appointment {
  margin-top: 10px;
  display: inline-block;
  font-size: 14px;
}

.view-find-exhibitors .schedule-appointment a {
  border-radius: 3px;
  color: $primary-color;
  border: 1px solid $primary-color;
  text-decoration: none;
  display: block;
  padding: 5px 20px;
  text-transform: uppercase;
  font-size: inherit;
}

.view-find-exhibitors .schedule-appointment a:hover {
  background: $primary-color-dark;
  color: #fff;
}

.float-left {
  float: left;
  margin-right: 1em;
}

.float-right {
  float: right;
  margin-left: 1em;
}

.page-node .node-page .field-name-body .speakers-highlight {
  margin: 0 0 1.5em 0;
  font-family: 'avenir_next_lt_proregular';
}

#speakers-seminars .mod {
  font-size: .8em;
}

#speakers-seminars h2 {
  font-size: 21px;
  line-height: 1.2;
  color: #fff;
  background: $primary-color-dark;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 5px 5px #ccc;
}

#speakers-seminars h3 {
  font-size: 18px;
  text-align: center;
}

#speakers-seminars h4 {
  font-size: 18px;
}

#speakers-seminars .time {
  margin: 0 -20px 10px -20px;
  padding: 15px 20px;
}

#speakers-seminars .time::before {
  background: url('../images/time.png') no-repeat center transparent !important;
  content: '';
  display: inline-block;
  width: 17px;
  height: 18px;
  margin-right: 5px;
}


#speakers-seminars h5,
#speakers-seminars h6 {
  font-size: 14px;
  margin: 0;
  font-style: italic;
}

#speakers-seminars h6 {
  color: #555;
}

.speaker-profile__info-detail {
  display: none;
  text-align: left;
}

.toggle-details {
  color: $primary-color-dark;
  cursor: pointer;
  background: url('../images/chevron-down.svg') no-repeat right 10px center;
  background-size: 12px;
  display: inline-block;
  padding-right: 30px;
}

.toggle-details.expand {
  background: url('../images/chevron-up.svg') no-repeat right 10px center;
  background-size: 12px;
}

#speakers-seminars .speaker-profile.presenter {
  margin-bottom: 20px;
}

#speakers-seminars .speaker-profile.presenter:last-child {
  margin-bottom: 0;
}

#speakers-seminars .speaker-profile.presenter img {
  width: 160px;
}

#speakers-seminars .speaker-profile h4 {
  margin-bottom: 5px;
  margin-top: 10px;
  font-family: 'avenir_next_lt_probold';
}

.secondary-speaker-header {
  background: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 5px 5px #ccc;
}

#speakers-seminars .main-speaker-name {
  color: black;
}

#speakers-seminars .term {
  font-size: 14px;
  color: #666;
  line-height: 1.3;
}

#speakers-seminars .speaker-profile span,
#speakers-seminars .speaker-profile em {
  display: block;
}

#speakers-seminars .workshop-profile img {
  width: 100%;
}


#speakers-seminars .center {
  text-align: center;
}

.speaker-profile {
  &__wrapper {
    height: 100%;
    -webkit-box-shadow: 0px 5px 5px #cacaca;
      box-shadow: 0px 5px 5px #cacaca;
    background: $lightest-gray;
    border-radius: 0 0 5px 5px;
  }
}

.speaker-profile__image,
.speaker-profile__info {
  display: table-cell;
}

.speaker-profile__image {
  width: 40%;
}

.speaker-profile__info {
  width: 60%;
  padding-left: 20px;
  vertical-align: middle;
  line-height: 1.5;
}

/*#speakers-seminars .center img {
  width: 150px !important;
  height: 189px !important;
}*/

.secondary-speaker-group .location {
	display: block;
	font-family: 'avenir_next_lt_proitalic';
}

.main-speaker-group {
  margin-bottom: 1em;
}

.main-speaker-group ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.main-speaker-group ul li {
  list-style: none;
  background: $lightest-gray;
  padding: 20px;
  border-radius: 5px;
  width: 49%;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 5px 5px #cacaca;
    box-shadow: 0px 5px 5px #cacaca;
}

.main-speaker-group li h2.nomargin {
  margin: -20px -20px 0 -20px;
}

.main-speaker-group li h5 {
  margin-bottom: 10px !important;
}

.secondary-speaker-group > h3 {
  background: #fff;
  padding: 10px;
  margin: 0 0 15px 0 !important;
}

.secondary-speaker-group .profile-wrapper {
	display: table;
	width: 100%;
}

.secondary-speaker-group .profile-wrapper .workshop-profile {
	display: table-cell;
  width: 50%;
}

.secondary-speaker-group .workshop-profile {
	padding: 10px;
}

.speaker-group ul {
  padding: 0;
  margin: 0;
  justify-content: center;
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.speaker-group ul li {
  list-style: none;
  width: 33.33333333%;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  text-align: center;
}

#speakers-seminars .speaker-group h2 {
  background: $primary-color-dark;
}

.speaker-group {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 3px #cacaca;
    box-shadow: 0px 3px 3px #cacaca;
}

.speaker-group h3 {
  margin: 0 0 20px 0 !important;
}

.speaker-group .speaker-profile {
  padding: 20px;
  border-radius: 5px;
}

.speaker-group .speaker-profile__image {
  display: block;
  width: 60%;
  margin: 0 auto;
}

.speaker-group .speaker-profile__info {
  display: block;
  width: 100%;
}

.speaker-group .speaker-profile__info {
  padding: 0;
}

.speaker-group ul li.wide {
	width: 45%;
  padding: 20px 16px;
  overflow: hidden;
}

.speaker-group ul li span {
  display: block;
}

table.schedule {
  margin-bottom: 1em;
}

table.schedule th {
  background: #f5f5f5;
  padding: 1em;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #e3e7e7;
}

table tr {
  border-top: 1px solid #e3e7e7;
  border-bottom: 1px solid #e3e7e7;
}

table td {
  background: #fff;
  padding: 1em;
  vertical-align: middle;
}

table.schedule td.time {
  white-space: nowrap;
  text-align: right;
  width: 1%;
}

table.schedule td.event, table.schedule td.exhibitor {
  font-weight: bold;
}

table.schedule td.location {
  text-align: right;
}

.page-user-appointment form.confirmation {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.page-user-appointment form.confirmation div.confirm {
  font-weight: bold;
  background: #EAE5DB;
  padding: 10px;
  margin-bottom: 1em;
}

.page-user-appointment form.confirmation .form-item-message {
  margin-bottom: 1em;
}

.page-user-appointment form.confirmation .form-actions {
  margin-top: 1em;
}

.page-user-appointment form.confirmation .form-actions #edit-submit {
  margin: 0 10px 0 0;
}

#views-exposed-form-view-registered-retailers-page {
  position: relative;
}

#views-exposed-form-view-registered-retailers-page .views-exposed-widgets .views-exposed-widget label {
  font-weight: normal;
  display: block;
  margin-bottom: .25em;
}

.filter-options-handler {
  display: none;
}

.page-view-exhibitors-by-country .content,
.page-view-exhibitors-by-category .content {
  max-width: 500px;
  margin: 0 auto;
}

.page-view-exhibitors-by-country #content ul,
.page-view-exhibitors-by-category #content ul {
  margin: 0;
}

.page-view-exhibitors-by-country #content ul li,
.page-view-exhibitors-by-category #content ul li {
  list-style-image: none;
}

.page-view-exhibitors-by-country #content ul li a,
.page-view-exhibitors-by-category #content ul li a {
  display: block;
  background: url('../images/right-arrow.png') no-repeat right center #fff;
  padding: 15px 20px 15px 15px;
  border-bottom: 1px solid #ccc;
  text-decoration: none;
}

.page-view-exhibitors-by-country #content ul li ul,
.page-view-exhibitors-by-category #content ul li ul {
  max-width: auto;
}

.page-view-exhibitors-by-category #content li span.parent-category {
  display: block;
  background: url('../images/open-icon.png') no-repeat right center #fff;
  padding: 15px 20px 15px 15px;
  border-bottom: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}

.page-view-exhibitors-by-category #content li.child-expanded span.parent-category {
  background: url('../images/close-icon.png') no-repeat right center $primary-color;
  color: #fff;
}

.page-view-exhibitors-by-category #content li.child-expanded ul li a {
  background-color: #f5f5f5;
}

.page-view-exhibitors-by-category .view-products .view-header a {
    display: inline-block;
    padding: 10px 10px 10px 25px;
    margin-bottom: 10px;
    text-decoration: none;
    background: url('../images/back-icon.png') no-repeat 10px center #fff;
  }

  .page-view-exhibitors-by-category .content h2,
  .page-view-exhibitors-by-country .content h2 {
    text-align: center;
    font-size: 18px;
  }

   #clear-btn {
    display: none;
   }

  .browse-links {
    display: none;
  }

  #modal-popup.download-gif,
  #modal-popup-download.download-gif {
    text-align: center !important;
  }

  #modal-popup.download-gif p,
  #modal-popup-download.download-gif p {
    font-size: 15px;
    margin: 1em 0 0 0;
  }


/* Exhibitor invitation */
#node-12135.node-webform table#previous-invites {
	margin: 0;
}

.table-previous-invites-wrapper {
	overflow-x: auto;
}

.previous-invites-wrapper {
	background: #f5f5f5;
	padding: 20px;
	border-radius: 5px;
}

.instructions {
  display: table;
  width: 100%;
  margin: 0;
  background: #fff;
  padding: 30px;
  border-radius: 5px 5px 0 0;
}

.instructions-col {
  display: table-cell;
  vertical-align: middle;
}

.instructions-col__left {
  padding-right: 40px;
}

.instructions-col__right {
  width: 45%;
}

.instructions-col__right img {
  vertical-align: bottom;
}

#node-12135 .messages.info {
  padding: 27px !important;
  background: #fff;
  color: #333;
  box-shadow: 0 0 5px #bbb;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  margin: -20px -20px -20px -20px;
}

#node-12135 .messages.info::after {
	content: '';
	width: 60px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
  background: url('../images/icon-sprites.png') no-repeat;
  background-position-y: -1310px;
}

#node-12135 .messages.info.expand::after {
  background-position-y: -1376px;
}

#node-12135 .messages.info.expand {
	background: $primary-color-dark;
	color: #fff;
	border-radius: 5px 5px 0 0;
	margin-bottom: 20px;
}

#node-12135 .webform-component--heading h2 {
	margin: 10px 0 0 0;
}

#node-12135 .webform-component--heading span {
	font-style: italic;
}

#node-12135 fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

#node-12135 .instructions img {
	width: 100% !important;
	height: auto !important;
}

#node-12135 fieldset .fieldset-wrapper {
	padding: 20px;
	background-color: #fff;
	border-radius: 10px;
	margin-bottom: 10px;
  border: 1px solid #edeadf;
}

.node-type-webform .webform-client-form {
  padding: 20px;
  background: #f9f9f9;
  margin-top: 20px;
  border-radius: 5px;
}

#webform-client-form-12135 input:invalid {
  box-shadow: none;
}

.webform-component-fieldset.webform-component--buyer-information.form-wrapper {
  padding: 0;
  border: none;
}

.webform-component-fieldset.webform-component--buyer-information.form-wrapper label {
  text-align: center;
}

#node-12135 table.sticky-table thead tr th,
#node-12135 table.sticky-table tbody tr td {
	border-right: #cdcdcd solid 1px;
	border-left: #cdcdcd solid 1px;
	padding: 10px;
}

#node-12135 table.sticky-table tbody tr td:last-child,
#node-12135 table.sticky-table thead tr th:last-child {
	text-align: center;
}

#node-12135 table.sticky-header {
	margin-top: 0;
	border: 1px solid #cdcdcd;
}

#node-12135 table.sticky-header thead tr th {
	padding: 10px;
}

#node-12135 table.sticky-table thead tr th {
	border: #cdcdcd solid 1px;
}

#node-12135 table.sticky-table tbody tr {
	border-color: #eaeaea;
}

#node-12135 table.sticky-table tbody tr:last-child {
	border-bottom: #cdcdcd solid 1px;
}

#webform-client-form-12135 .webform-component-textfield, #webform-client-form-12135 .webform-component-email {
  float: left;
  margin: 0 1.1111111111111112% 0 0;
  width: 24.166666666666668%;
}

#node-12135 .webform-component--first-name,
#node-12135 .webform-component--last-name,
#node-12135 .webform-component--email-address {
	width: 31.88405797101449%;
	margin-right: 2.083333333333333%;
}

 #webform-client-form-12135 .webform-component-email {
 	margin-right: 0;
 }

#webform-client-form-12135 input[type="text"],
#webform-client-form-12135 input[type="email"] {
  max-width: 100%;
}

#webform-client-form-12135 label {
	margin-bottom: 5px;
}

#node-12135 .remove-retailer-info {
	display: inline-block;
	padding: 10px 20px;
	background: #ffbbbb;
	color: #333;
	margin-top: 10px;
	cursor: pointer;
}

#node-12135 #add-more {
	padding: 10px 20px;
	border: 1px solid $primary-color-dark;
  color: $primary-color-dark;
	margin-bottom: 20px;
	border-radius: 5px;
	font-size: 14px;
	text-transform: uppercase;
	background-color: #fff;
  cursor: pointer;
}

#node-12135 #add-more:hover {
	background-color: $primary-color-dark;
  color: #fff;
}

#webform-client-form-12135 input[type="submit"], .webform-submit {
  background-color: $primary-color;
  border-radius: 0;
  color: #fff;
  margin-top: 5px;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  padding: 15px 20px !important;
}

#webform-client-form-12135 input[type="submit"]:hover, .webform-submit:hover {
  background-color: $primary-color-dark;
}

#node-12135 .field-name-body p span {
  color: #333;
}

.node-type-webform #dialog table {
  border: 1px solid #92928c;
  margin: 1em 0;
  width: 100%;
}

.node-type-webform #dialog table th, .node-type-webform #dialog table td{
  border-color: #92928c;
  border-style: solid;
  border-width: 0 0 1px;
  padding: 0.7em;
}
/* End Exhibitor invitation */

#wrapper > #first.clearfix {
  margin-bottom: 1em;
}

.page-node .node-page .field-name-body img {
  border: 3px solid #fff;
  box-shadow: 0 0 5px #ccc;
}

/*updated styles*/
html, body.front.not-logged-in,
.not-logged-in #page-wrapper,
.front.not-logged-in #header,
.not-front.not-logged-in.page-user #page-wrapper,
.not-front.not-logged-in.page-plma-user #page-wrapper {
  height: 100%;
}

.not-front.not-logged-in #page-wrapper {
  height: auto;
  background: $body-bg;
}

.front.not-logged-in #page {
  height: 45%;
  min-height: 320px;
}

.front.not-logged-in .head-wrapper {
  position: relative;
  z-index: 100;
}

.front.not-logged-in #header-background {
  height: 100%;
  margin-top: -40px;
}

.front.not-logged-in #header-background::before {
  position: absolute;
  content: '';
  background: #8d1b1e;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.front.not-logged-in .branding {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 100%;
  margin-top: -125px;
  left: 0;
  right: 0;
  text-align: center;
}

.front.not-logged-in #header > .section {
  height: 100%;
}

.front.not-logged-in #name-and-slogan {
  display: table-cell;
  vertical-align: bottom;
  padding-bottom: 180px;
}

.not-front.not-logged-in #header-background, .logged-in #header-background {
  background: #8d1b1e;
}

.not-front.not-logged-in .branding, .logged-in .branding {
  margin-top: -30px;
  padding: 0 10px 10px 0;
}

#modal-popup.download-gif,
#modal-popup-download.download-gif {
  text-align: center !important;
}

#modal-popup.download-gif p,
#modal-popup-download.download-gif p {
  font-size: 15px;
  margin: 1em 0 0 0;
}

.sk-fading-circle {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

/*Confirm Message*/
.confirmation-page-form .confirm-message {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0,0,0,.6);
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
}

.confirm-message > .form-type-item {
  width: 100% !important;
}

.confirm-message .submit_announce_new_product {
  width: 100%;
}

.confirm-message > .form-type-item div.form-item {
  width: 100% !important;
}

.confirmation-page-form .confirm-message > .form-type-item {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 5px;
  background-color: #fff;
}

.confirmation_message_text {
  padding: 20px;
  background: #fff0dd;
  border-radius: 5px;
  border: 1px solid #eac596;
  margin-top: 20px;
}

.confirmation-page-form .confirmation_message_text {
  padding: 0;
  margin-top: 0;
  background: transparent;
  border: none;
}

.confirm-message .submit_announce_new_product,
.confirm-message .go_back_announce_new_product {
  display: inline-block;
  margin: 0 auto;
}

.confirm-message input[type="submit"]#edit-submit {
  width: 100%;
}

.go_back_announce_new_product input#edit-go-back {
  background: url('../images/go-back.png') no-repeat left 0px top 2px;
  color: #333;
  padding-left: 25px;
  outline: 0;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}

.go_back_announce_new_product input#edit-go-back:hover {
  color: $primary-color;
  background-color: transparent !important;
  background-position-y: -63px;
}

div.submit_announce_new_product input#edit-submit {
  white-space: normal;
}

/*Explore Number of Companies Exhibiting*/
.page-explore-exhibitors #main {
	position: relative;
}

.not-logged-in.page-explore-exhibitors #page-wrapper {
	display: block;
	background-color: #e2e7e7;
}

body.page-explore-exhibitors {
	height: auto;
	background: #333;
}

.page-explore-exhibitors .header {
  background: $primary-color;
}

.page-explore-exhibitors .header__user-menu .menu li a {
  background-position: top -32px center;
}

.page-explore-exhibitors .actionbar__wrapper {
	height: 65px;
}

.page-explore-exhibitors .header__site-name {
	display: none;
}

.page-explore-exhibitors .header__user-menu {
	width: 75%;
}

.page-explore-exhibitors #logo {
  padding-top: 0.9375rem;
  position: relative;
  z-index: 550;
}

.page-explore-exhibitors form .views-exposed-form {
	float: none;
	width: 100%;
	position: relative;
}

.page-explore-exhibitors .views-exposed-form::before {
	position: absolute;
	content: '';
	top: -20px;
	right: 0;
	left: 0;
	margin: auto;
	width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
}

.page-explore-exhibitors .views-exposed-form .views-exposed-widgets {
	padding: 40px;
	-webkit-box-shadow: 0px 0px 10px #ccc;
    box-shadow: 0px 0px 10px #ccc;
}

#edit-total-category-items,
#edit-total-sub-category-items,
#edit-total-product-items {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 40px #ccc;
    box-shadow: 0px 0px 40px #ccc;
  border-radius: 5px;
  margin: 20px 0;
  padding: 30px;
  text-align: center;
}

.page-explore-exhibitors .form-item label span {
	font-family: 'avenir_next_lt_proitalic';
	color: #666;
	font-weight: normal;
}

.page-explore-exhibitors .form-item label span::before {
	content: ' | ';
	margin: 0 10px;
	color: #ccc;
}

.page-explore-exhibitors form select {
	padding: 15px 20px;
	font-size: 16px;
	background-position: top -1454px right -5px;
}

.page-explore-exhibitors form .form-submit {
	display: none;
}

.page-explore-exhibitors #footer {
	position: inherit;
	font-size: 15px;
	background-color: #333;
}

.page-explore-exhibitors #footer p {
	color: #999;
}

.page-explore-exhibitors .company-count {
	font-family: 'avenir_next_lt_promedium';
	font-size: 60px;
	line-height: 1;
	color: #d7692a;
}

.page-explore-exhibitors .company-description {
	font-size: 20px;
	line-height: 1.3;
	color: #6e6e6e;
}

.page-explore-exhibitors .company-info {
	display: table;
	-webkit-box-shadow: 0px 0px 40px #ccc;
    box-shadow: 0px 0px 40px #ccc;
	width: 100%;
	margin-bottom: 40px;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
}

.page-explore-exhibitors .company-info strong {
	display: block;
}

.page-explore-exhibitors .companies-count,
.page-explore-exhibitors .register-link {
	display: table-cell;
	vertical-align: middle;
	padding: 40px;
	width: 50%;
	text-align: center;
}

.page-explore-exhibitors .companies-count h1 {
	font-family: 'avenir_next_lt_promedium';
	font-size: 82px;
	color: #d7692a;
	margin-bottom: 0;
	line-height: 1;
}

.page-explore-exhibitors .register-link {
	background: url('../images/trade-show-background.jpg') no-repeat center;
	background-size: cover;
	position: relative;
}

.page-explore-exhibitors .register-link a {
	font-family: 'avenir_next_lt_promedium';
	position: relative;
	z-index: 100;
	display: inline-block;
	color: #d7692a;
	background-color: #fff;
	text-align: center;
	border-radius: 3px;
	height: 100%;
	padding: 20px;
	line-height: 1;
	text-transform: uppercase;
}

.page-explore-exhibitors #page-title {
	position: absolute;
	top: -65px;
	left: 290px;
	line-height: 1.2;
	z-index: 1000;
	margin: 0;
	font-size: 30px;
}

.page-explore-exhibitors .menu {
  display: none;
}

.view-kitchen-and-housewares .views-field-field-company {
  text-transform: uppercase;
}

@media only screen and (min-width: 1180px) {
	.page-explore-exhibitors #page-title {
		font-size: 36px;
		left: 310px;
		top: -70px;
	}
}
